import React, { useState } from "react";
import { Button, Card, Badge, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

const PropertyDetail = ({ property, onBack, assetUrl }) => {
  const [status, setStatus] = useState(property.status.name === "Available");
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="p-3 border rounded bg-white">
      <Button
        variant="primary"
        onClick={onBack}
        className="mb-3"
        size="m"
        style={{ fontSize: "1em", padding: "8px 15px", lineHeight: "1" }}
      >
        Back
      </Button>
      <Card className="p-3">
        <div className="d-flex align-items-stretch">
          {/* Image Section */}
          <Card.Img
            variant="top"
            src={`${assetUrl}${property.images[0].image_url}`}
            alt={property.title}
            style={{
              width: "500px",
              height: "400px",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />

          {/* Details Section */}
          <Card.Body className="ms-3 d-flex flex-column justify-content-between">
            <div >
              <h3 className="m-0">{property.title}</h3>

              <div
                className="mb-3"
                style={{ fontSize: "0.8em", color: "gray" }}
              >
                <ToggleButtonGroup
                  type="checkbox"
                  value={status}
                  onChange={() => setStatus(!status)}
                  // className="mb-2"
                  style={{ marginLeft:`${property.title.length}em` }}
                >
                  <ToggleButton
                    value={true}
                    size="sm"
                    style={{
                      fontSize: "0.8em",
                      // padding: "5px 5px",
                      lineHeight: "1",
                      borderRadius: "12px",
                      // marginLeft: "45px",
                      marginTop: "-15px",
                    }}
                  >
                    Available
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <p className="text-primary fw-bold fs-5">
                ₹{property.budget.toLocaleString()}
              </p>
              <p>
                <Badge className="px-3 py-2 me-2">{property.purpose.name} </Badge>
                <Badge className="px-3 py-2 me-2">{property.type.name} </Badge>
                <Badge className="px-3 py-2 me-2"> {property.sub_type.name}</Badge>
              </p>
              <p>
                <Badge className="px-3 py-2 me-2">{property.state.name}</Badge>
                <Badge className="px-3 py-2 me-2"> {property.city.name}</Badge>
              </p>
              <p>
                {property.structure.bed_rooms} Beds {property.structure.bath_rooms} Bath
              </p>
              <p>{property.state.name}</p>
              <p>{property.land_mark}</p>

              {/* Overview and Description */}
              <h5>Overview:</h5>
              <p>
                {expanded
                  ? property.description
                  : `${property.description.split(" ").slice(0, 20).join(" ")}...`}
              </p>
              {property.description.length > 50 && (
                <p onClick={toggleExpanded} className="text-primary fw-bold fs-6">
                  {expanded ? "Read Less" : "Read More"}
                </p>
              )}

              {/* Facilities */}
              <h5>Facilities:</h5>
              <ul className="row list-unstyled">
                {property.feature.swimming_pool && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Swimming Pool
                  </li>
                )}
                {property.feature.gas_connection && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Gas Connection
                  </li>
                )}
                {property.feature.ro && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> RO
                  </li>
                )}
                {property.feature.club_house && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Club House
                  </li>
                )}
                {property.feature.basketball_court && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Basketball Court
                  </li>
                )}
                {property.feature.tennis_court && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Tennis Court
                  </li>
                )}
                {property.feature.gym && (
                  <li className="col-4 d-flex align-items-center">
                    <span className="badge badge-success rounded-circle mr-2">✓</span> Gym
                  </li>
                )}
              </ul>
            </div>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

export default PropertyDetail;