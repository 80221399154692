import React from "react";

const getInitials = (name) => {
  if (!name) return "U"; // Default initial if no name
  const words = name.split(" ");
  return words.length > 1
    ? `${words[0][0]}${words[1][0]}`.toUpperCase()
    : words[0][0].toUpperCase();
};

const getColorFromChar = (char) => {
  const colors = [
    "#FF5733", "#33FF57", "#5733FF", "#FFC300", "#FF33A1", "#33A1FF",
    "#FF69B4", "#33CC33", "#6666CC", "#CC6633", "#33CCCC", "#CC33CC",
    "#6633CC", "#33CC66", "#CC3366", "#66CC33", "#3366CC", "#CC6633",
    "#33CC99", "#CC3399", "#336699", "#99CC33", "#CC9966", "#6699CC",
    "#CC6699", "#3366CC", "#99CC66", "#CC3366", "#66CC99", "#336699",
  ];
  const charCode = char.charCodeAt(0);
  const index = charCode % colors.length;
  return colors[index];
};

const Avatar = ({ icon, size, src}) => {
  const firstName = icon;
  
  if (icon?.photo) {
    return (
      <span className="d-flex">
        <img
          src={src}
          alt={`${firstName}'s profile`}
          className="rounded-circle shadow-sm"
          style={{ width: `${size}px`, height: `${size}px`, objectFit: "cover", border: "1px solid #777" }}
        />
      </span>
    );
  }

  const initials = getInitials(firstName);
  const color = getColorFromChar(initials[0]);

  return (
    <div
      className="rounded-circle shadow-sm d-flex justify-content-center align-items-center"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        fontSize: size / 2.5,
        color: "white",
        fontWeight: "bold",
        border: "1px solid #777"
      }}
    >
      {initials}
    </div>
  );
};

export default Avatar;