import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, { showToast } from "../../../components/common/notification/Notification";
import AdminHeader from "../../common/Header";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Spinner from "../../../components/common/spinner/Spinner";

const EditPropertySubSubType = () => {
  // Get user details from Redux
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;

  // Get Property Sub Sub Type ID from URL
  const { propertySubSubTypeId } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Local state
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({ name: "", description: "" });

  useEffect(() => {
    const fetchSubSubTypeData = async () => {
      if (!propertySubSubTypeId) {
        console.error("propertySubSubTypeId is undefined! Cannot fetch data.");
        showToast("Invalid Property Sub Sub Type ID", { type: "error" });
        navigate("/property-sub-sub-type");
        return;
      }
      if (!token) {
        console.error("Token is missing. Redirecting to login.");
        showToast("Unauthorized! Please log in again.", { type: "error" });
        navigate("/login");
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(
          `${baseUrl}/property/sub/sub/type/${propertySubSubTypeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response?.data?.statusType === "SUCCESS") {
          const postData = response.data.propertySubSubType;
          setFormData({ name: postData.name, description: postData.description });
        } else {
          setIsError(true);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          showToast("Unauthorized! Please log in again.", { type: "error" });
          navigate("/login");
        } else {
          showToast("Error Occurred", { type: "error" });
        }
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubSubTypeData();
  }, [propertySubSubTypeId, token, navigate, baseUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.description) {
      showToast("Please fill in all fields.", { type: "error" });
      return;
    }

    try {
      setIsLoading(true);
      const requestBody = {
        id: propertySubSubTypeId,
        name: formData.name,
        description: formData.description,
      };

      const response = await axios.put(
        `${baseUrl}/property/sub/sub/type/edit`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response?.data?.statusType === "SUCCESS") {
        showToast("Property Sub Sub Type updated successfully!", { type: "success" });

        // Delay navigation to show success message
        setTimeout(() => {
          navigate("/property-sub-sub-type");
        }, 3000);
      } else {
        showToast("Failed to update Property Sub Sub Type.", { type: "error" });
      }
    } catch (error) {
      if (error.response?.status === 401) {
        showToast("Unauthorized! Please log in again.", { type: "error" });
        navigate("/login");
      } else {
        showToast("Error occurred while updating.", { type: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { label: "Home", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Sub Sub Types", link: "/property-sub-sub-type" },
    { label: "Edit Property Sub Sub Type", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <h3>Edit Property Sub Sub Type</h3>
            {(() => {
              if (isLoading) {
                return <Spinner />;
              } else if (isError) {
                return <p className="text-danger">Failed to load data. Please try again.</p>;
              } else {
                return (
                  <Form onSubmit={handleSubmit} className="mb-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Enter name"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Enter description"
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      {isLoading ? <Spinner loading={true} /> : "Save Changes"}
                    </Button>
                    <Link to="/property-sub-sub-type" className="btn btn-secondary ms-3">
                      Cancel
                    </Link>
                  </Form>
                );
              }
            })()}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditPropertySubSubType;