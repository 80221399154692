import React, { useState } from "react";
import {
  Col,
  Badge,
  Card,
  Carousel,
  Tooltip,
  OverlayTrigger,
  Alert,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  FaBookmark,
  FaEye,
  FaEyeSlash,
  FaPlus,
  FaUserPlus,
} from "react-icons/fa";
import "./property.style.css";
import Api from "../api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import axios from "axios";
import Image from "../../../assets/images/noimage.jpg";
import { setFormData } from "../../../features/formData/formSlice";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const PropertySingleCard = ({ data, assetUrl, fetchPropertyData }) => {
  const { userDetails, token } = useSelector((state) => state.auth.user);
  const userId = userDetails?.id;
  const [datas, setDatas] = useState([]);
  const dispatch = useDispatch();
  const userType = userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyData, setPropertyData] = useState(data);
  const [itemIdToPublish, setItemIdToPublish] = useState(null);
  const [showPublishAlert, setShowPublishAlert] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handlePublishModalClose = () => setShowPublishModal(false);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = (itemId) => {
    setShowDeleteAlert(true);
    setItemIdToDelete(itemId);
  };

  const handlePublishAlertClose = () => setShowPublishAlert(false);
  const handlePublishAlertShow = (singleObject) => {
    setShowPublishAlert(true);
    setItemIdToPublish(singleObject);
  };
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const togglePropertyVisibility = () => {
    handleHideData(itemIdToPublish);
  };
  const handlePublishModalShow = (singleObject) => {
    setShowPublishModal(true);
    setItemIdToPublish(singleObject);
  };

  const handleDeleteModalShow = (itemId) => {
    setShowDeleteModal(true);
    setItemIdToDelete(itemId);
  };
  const dataFetch = async () => {
    await fetchPropertyData();
  };

  const handleHideData = async (item) => {
    console.log("hello from handle publish");
    console.log(item);
    try {
      const response = await axios.put(
        `${baseUrl}/property/publish/status`,
        {
          lead_id: item.id,
          is_publish: !item.is_published, // Update the published status to its opposite value
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.data")
      console.log(response.data)
      if (response?.data?.statusType === "SUCCESS") {
        const updatedData = propertyData.map((singleData) =>
          singleData.lead_id === item.lead_id // Update the condition to match the item.id
            ? { ...singleData, is_published: !singleData.is_published }
            : singleData
        );
        setPropertyData(updatedData);
        item.is_published = !item.is_published; // Update the published property of the item object
        if (item.is_published) {
          showToast("Property Published successfully", {
            type: "success",
          });
        } else {
          showToast("Property Unpublished successfully", {
            type: "success",
          });
        }
        handlePublishModalClose();
      } else {
        showToast("Failed to update property", {
          type: "error",
        });
      }
    } catch (error) {
      if (error.response) {
        console.error("Error updating property:", error.response.data);
        showToast("Failed to update property", {
          type: "error",
        });
      } else if (error.request) {
        console.error("Error updating property:", error.request);
        showToast("Failed to update property", {
          type: "error",
        });
      } else {
        console.error("Error updating property:", error.message);
        showToast("Failed to update property", {
          type: "error",
        });
      }
    }
  };
  
  const handleDelete = async (itemId) => {
    try {
      const filterData = propertyData.filter(
        (singlePropertyData) => singlePropertyData.lead_id === itemIdToDelete
      );
      let response;

      if (filterData[0]?.id === 0) {
        console.log("hello form here", itemIdToDelete);
        response = await axios.delete(
          `${baseUrl}/property/lead/delete/${itemIdToDelete}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await axios.delete(
          `${baseUrl}/property/deleteProperty/${itemIdToDelete}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setDatas(datas.filter((item) => item.id !== itemId));
        showToast("Property deleted successfully", {
          type: "success",
        });
        handleDeleteModalClose();
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
        showToast("Unable to delete property", {
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const addFavouriteApi = Api({ url: `property/favorite/add` });

  const handleFollowOption = async (id, isFavorite) => {
    const dataId = { lead_id: +id };
    console.log(isFavorite);
    if (isFavorite) {
      try {
        console.log("isFavorite:", id);
console.log("Delete URL:", `${baseUrl}/property/favorite/delete/${isFavorite}`);

        const removeFavourite = await axios.delete(
          `${baseUrl}/property/favorite/delete/${isFavorite}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (removeFavourite.data.statusType === "SUCCESS") {
          showToast("Property Removed From Favourite", { type: "success" });
          const updatedData = propertyData.map((singleData) =>
            singleData.lead_id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: null,
                }
              : singleData
          );
          setPropertyData(updatedData);
        } else {
          showToast("Error Removing Property From Favourite", {
            type: "error",
          });
        }
      } catch (error) {
        showToast("Error Removing Property From Favourite", { type: "error" });
      }
    } else {
      try {
        const addFavourite = await addFavouriteApi.postData(dataId);
        console.log(addFavourite);
        if (addFavourite.data.statusType === "SUCCESS") {
          const updatedData = propertyData.map((singleData) =>
            singleData.lead_id === +id
              ? {
                  ...singleData,
                  is_favorite: !singleData.is_favorite,
                  favorite_id: addFavourite.data.favorite.id,
                }
              : singleData
          );
          setPropertyData(updatedData);
          showToast("Property Added To Favourite", { type: "success" });
        } else {
          showToast("Error Adding Property To Favourite", { type: "error" });
        }
      } catch (error) {
        showToast("Error Adding Property To Favourite", { type: "error" });
      }
    }
  };

  const renderTooltip = (note) => <Tooltip id="button-tooltip">{note}</Tooltip>;
  const getBadgeColor = (purpose) => {
    switch (purpose) {
      case "Buy":
        return "border border-success";
      case "Sell":
        return "border border-danger";
      case "Rent":
        return "border border-secondary";
      default:
        return "border";
    }
  };

  const addFormData = (id) => {
    const filterData = data.filter((singleData) => singleData?.id === +id);

    console.log(filterData);
    const formData = {
      propertyPurpose: filterData[0].purpose?.id,
      propertyType: filterData[0].type?.id,
      city: filterData[0]?.city?.id,
      state: filterData[0]?.state?.id,
      area: filterData[0]?.area?.name,
      lead_id: filterData[0]?.lead_id,
      name: filterData[0]?.title,
      price: filterData[0]?.budget,
      description: filterData[0]?.description,
      country_name: 1,
      propertySubtype: +filterData[0]?.sub_type?.id,
      featured_property: filterData[0].featured_property,
      images: filterData[0].images,
    };

    console.log(formData);
    dispatch(setFormData(formData));
  };

  

  return (
    <>
      <Notification />
      <div style={{ padding: "0rem 1rem" }}>
        <Modal
          show={showDeleteModal}
          onHide={handleDeleteModalClose}
          centered
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">Are you sure you want to delete this property?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleDeleteModalClose}
              variant="outline-danger"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="danger">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showPublishModal}
          onHide={handlePublishModalClose}
          centered
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-success">Confirm Publish</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">Are you sure you want to publish this property?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handlePublishModalClose}
              variant="outline-secondary"
              className="me-3"
            >
              Cancel
            </Button>
            <Button onClick={togglePropertyVisibility} variant="success">
              {console.log(itemIdToPublish)}
              {itemIdToPublish?.is_published ? "Un Publish" : "Publish"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {propertyData?.map((singleData) => (
        <Col lg={6} md={12} sm={12} >
        
          <div
            key={singleData.id}
            className={`mainPropertyCard ${getBadgeColor(
              singleData?.purpose?.name
            )}`}
          >
            <div className="mainPropertyCardFirst">
              <div className="topTags">
                <Badge
                  bg={
                    singleData?.purpose?.name === "Buy"
                      ? "warning"
                      : singleData?.purpose?.name === "Sell"
                      ? "warning"
                      : singleData?.purpose?.name === "Rent"
                      ? "warning"
                      : "warning"
                  }
                >
                  {singleData?.purpose?.name}
                </Badge>
                <Badge
                  bg="primary"
                >
                  {singleData?.type?.name}
                </Badge>
                <Badge
                  bg="success"
                >
                  {singleData?.sub_type?.name}
                </Badge>
                {singleData?.is_favorite && (
                  <Badge
                    bg="success"
                    style={{ fontSize: "14px", marginLeft: "1rem" }}
                  >
                    Followed
                  </Badge>
                )}
              </div>
            </div>
            <div className="mainPropertyCardSecond">
              <div className="mainPropertySecondFirst">
                <div className="swiper-image">
                  {singleData?.images?.length > 0 ? (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      {singleData?.images?.map((singleImage) => (
                        <SwiperSlide style={{ borderRadius: "0.35rem" }}>
                          {singleImage?.image_url?.includes("mp4") ? (
                            <video
                              autoPlay
                              muted
                              loop
                              controls
                              className="main-video"
                            >
                              <source
                                src={`${assetUrl}${singleImage.image_url}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              className="main-image"
                              src={`${assetUrl}${singleImage.image_url}`}
                              alt="Property"
                            />
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={1}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      className="swiper"
                    >
                      <SwiperSlide>
                        <img
                          src={Image}
                          alt="no image"
                          className="main-image"
                        />
                      </SwiperSlide>
                    </Swiper>
                  )}
                </div>
                <div className="image-overlay-pro"></div>
                <div>
                  {" "}
                  <div className="property-details">
                    <div className="property-location">
                      <div className="badge-like">
                        <span className="location-label">State:</span>
                        <span className="location-value">
                          {singleData?.state?.name}
                        </span>
                      </div>
                      <div className="badge-like middleBadge">
                        <span className="location-label">City:</span>
                        <span className="location-value">
                          {singleData?.city?.name}
                        </span>
                      </div>
                      <div className="badge-like">
                        <span className="location-label">Area:</span>
                        <span className="location-value">
                          {singleData?.area?.name}
                        </span>
                      </div>
                    </div>

                    {/* Description Section */}
                    <div >
                      <h4 className="property-title">{singleData?.title}</h4>
                      <div className="property-description">
                        {singleData?.description.length > 100 ? (
                          <>
                            {parse(
                              `${singleData?.description?.substring(0, 200)}...`
                            )}
                            {singleData?.id === 0 ? (
                              <Link
                                variant="outline-primary"
                                className="p-0 text-primary fw-bold"
                                to={`/post/${singleData?.lead_id}`}
                              >
                                More
                              </Link>
                            ) : (
                              <Link
                                variant="link"
                                className="p-0 text-primary fw-bold"
                                to={`/property/${singleData?.id}`}
                              >
                                More
                              </Link>
                            )}
                          </>
                        ) : (
                          parse(singleData?.description)
                        )}
                      </div>
                    </div>
                  <p class="property-price">₹{singleData?.budget}</p>
                  </div>
                </div>
              </div>
              <div className="property-footer">

                <div className="actions-btn">
                  {singleData?.customer?.id !== userId && (
                    <Link
                      variant="outline-success"
                      className="btn btn-success btn-top-card"
                      onClick={() =>
                        handleFollowOption(
                          singleData?.lead_id,
                          singleData?.favorite_id
                        )
                      }
                      title={singleData?.is_favorite ? "Unfollow" : "Follow"}
                    >
                      <i
                        className={`fa-solid fa-user-${
                          singleData?.is_favorite ? "minus" : "plus"
                        }`}
                      ></i>
                    </Link>
                  )}
                  {singleData?.id === 0 ? (
                    <Link
                      variant="outline-success"
                      className="btn btn-success "
                      to={`/post/${singleData?.lead_id}`}
                    >
                      <i class="fa-solid fa-expand"></i>
                    </Link>
                  ) : (
                    <Link
                      variant="outline-success"
                      className="btn btn-success "
                      to={`/property/${singleData?.id}`}
                      title="View"
                    >
                      <i class="fa-solid fa-expand"></i>
                    </Link>
                  )}

                  {(singleData?.customer?.id === userId ||
                    userType === "SUPER_ADMIN") && (
                    <>
                      <Button
                        bg={singleData?.is_published ? "success" : "danger"}
                        onClick={() => handlePublishModalShow(singleData)}
                        title={
                          singleData?.is_published || singleData?.published
                            ? "UnPublish"
                            : "Publish"
                        }
                      >
                        {singleData?.is_published || singleData?.published ? (
                          <FaEyeSlash />
                        ) : (
                          <i class="fa-solid fa-upload"></i>
                        )}
                      </Button>
                      {singleData?.id === 0 ? (
                        <>
                          <Link
                            variant="outline-danger"
                            className="btn btn-warning  btn-top-card"
                            to={`/post/${singleData?.id}/edit`}
                            title={"Edit"}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                          <Link
                            variant="outline-danger"
                            className="btn btn-danger  btn-top-card"
                            title={"Delete"}
                          >
                            <i
                              class="fa-solid fa-trash"
                              onClick={() =>
                                handleDeleteModalShow(singleData?.lead_id)
                              }
                            ></i>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            variant="outline-danger"
                            className="btn btn-warning  btn-top-card"
                            to={`/property/${singleData?.id}/edit`}
                            title={"Edit"}
                          >
                            <i className="fa fa-edit"></i>
                          </Link>
                          <Link
                            variant="outline-danger"
                            className="btn btn-danger  btn-top-card"
                            title={"Delete"}
                          >
                            <i
                              class="fa-solid fa-trash"
                              onClick={() =>
                                handleDeleteModalShow(singleData?.id)
                              }
                            ></i>
                          </Link>
                        </>
                      )}
                      {singleData?.id === 0 && (
                        <>
                          {singleData?.property_id != 0 &&
                          singleData?.property_id != null ? (
                            <Link
                              className="btn btn-primary"
                              to={`property/${singleData?.property_id}`}
                            >
                              View Property
                            </Link>
                          ) : (
                            <div
                              className="btn btn-primary text-white"
                              onClick={() => {
                                addFormData(singleData?.id);
                              }}
                              title="Add Property"
                            >
                              <Link
                                className="text-white"
                                to={`/property/add-property`}
                              >
                                <FaPlus />
                              </Link>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </>
  );
};

export default PropertySingleCard;


// *{
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// .property-card {
//   /* box-shadow:
//     1px 1px 100px rgba(0, 0, 0, 0.07)
//   ;
// ; */
// }
// :root {
//   --bg: #e8b5db;
//   --ribbon1: #4f86f7;
//   --ribbon2: #ff7f7f;
//   --ribbon3: #bf7fff;
// }
// /* $theme-bg: #1e1935;
// $theme-bg2: lighten(#1e1935, 30%);
// $theme-color: #fff;
// $ribbon-width: 300px; */

// .c-ribbon {
//   position: absolute;
//   display: block;
//   background: #198754;
//   color: #fff;
//   text-decoration: none;
//   padding: 5px;
//   text-align: center;
//   width: 10rem;
//   z-index: 100;
//   transform: rotate(-45deg);
//   top: 1px;
//   left: -61px;
// }
// &:hover {
//   box-shadow: 5px 5px 5px darken(#1e1935, 5%);
// }
// .ribbon {
//   position: absolute;
//   top: 10px;
//   left: -3px;
//   background-color: var(--ribbon1);
//   color: white;
//   padding: 5px 15px;
//   font-size: 12px;
//   font-weight: bold;
//   transform: rotate(-30deg);
//   transform-origin: left top;
//   z-index: 1 !important;
//   line-height: 34px;
// }

// .ribbon:before,
// .ribbon:after {
//   height: 0;
//   width: 0;
//   content: "";
//   position: absolute;
//   border-style: solid;
//   border-width: 0;
//   border-color: transparent var(--ribbon1);
// }

// .ribbon:before {
//   left: -20px;
//   border-width: 15px 20px 20px 0;
// }

// .ribbon:after {
//   right: -20px;
//   border-width: 15px 0 20px 20px;
// }

// .card-header {
//   /* border: none !important; */
// }
// /* src/PropertyCard.css */
// .property-card {
//   /* margin: 20px; */
//   padding: 15px;
//   /* border: 1px solid #ddd; */
//   /* border-radius: 5px; */
//   margin-top: 1rem;
// }
// .property-image {
//   width: 100%;
//   height: 300px; /* Set a fixed height for the carousel */
//   overflow: hidden;
//   border-radius: 5px;
// }

// .property-details {
//   flex-grow: 1;
//   /* display: flex;
//     flex-direction: column;
//     justify-content: space-between; */
// }

// .property-location {
//   font-size: 14px;
//   color: #374151;
//   display: flex;
//   gap: 10px;
//   flex-wrap: wrap;
// }

// .property-title {
//   font-weight: 600;
//   margin: 10px 0;
//   margin-bottom: 0px;
// }

// .property-description {
//   font-size: 14px;
//   color: #666;
//   margin-bottom: 0px;
//   margin-top: 5px;
// }

// .more-button {
//   align-self: flex-start;
// }

// .property-actions {
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
// }

// .property-actions button {
//   margin-bottom: 5px;
// }
// /* src/PropertyList.css */
// .property-location {
//   font-size: 14px;
//   color: #666;
//   margin-bottom: 10px;
// }

// .badge-like {
//   background-color: rgb(243 244 246); /* Background color of the badge */
//   padding: 3px 12px;
//   border-radius: 6px; /* Adjust as needed */
//   margin-right: 5px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: inline-block;
//   color: #888;
// }


// .mainDataDiv {
//   display: flex;
//   align-items: center;
//   /* justify-content: space-between; */
//   margin-bottom: 1rem;
//   gap: 15px;
// }

// .badgeDataDiv {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: auto;
//   height: 2rem;
//   border: 1px solid #000;
//   padding: 1rem 1rem;
//   border-radius: 2rem;
// }
// .iconDataDiv .faBed {
//   font-size: 18px;
//   display: flex;
//   align-items: center;
//   /* color: rgb(32, 237, 87); */
//   justify-content: center;
//   font-weight: 600;
// }
// .roomDataDiv .small {
//   /* font-size: 20px; */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: 6px;
// }
// .small {
//   font-weight: 600;
//   font-size: 14px;
// }
// /* App.css */

// .pdf-card {
//   border: 1px solid #ddd;
//   border-radius: 8px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   width: 300px;
//   margin: 20px;
// }

// .pdf-preview {
//   height: 400px;
//   overflow: hidden;
// }

// .pdf-actions {
//   padding: 10px;
//   text-align: center;
//   background-color: #f5f5f5;
// }

// .download-button {
//   display: inline-block;
//   padding: 10px 20px;
//   background-color: #007bff;
//   color: #fff;
//   border-radius: 4px;
//   text-decoration: none;
// }

// .download-button:hover {
//   background-color: #0056b3;
// }

// @media (max-width: 1090px) {
//   .mainCardBody {
//     flex-direction: column;
//   }
//   .property-actions {
//     margin-top: 2.4rem !important;
//     flex-direction: row !important;
//   }
//   .property-card {
//     border: 3px solid;
//     display: flex;
//     height: auto !important;
//     width: auto;
//   }

//   .property-actions {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//   }
// }
// @media (max-width: 768px) {
//   .cardBody {
//     flex-direction: column; /* Stack items vertically on smaller screens */
//   }

//   .property-image {
//     width: 100%;
//     height: auto; /* Adjust height for smaller screens */
//     object-fit: cover !important;
//   }

//   .property-details {
//     margin-left: 0;
//     margin-top: 15px;
//   }
//   .property-card {
//     border: 3px solid;
//     display: flex;
//     height: auto !important;
//     width: auto;
//   }

//   .property-actions {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//     /* flex-direction: row; */
//   }
//   .property-actions {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//   }

//   .property-actions button {
//     width: 100%; /* Ensure buttons are full width on smaller screens */
//   }
  
//   .badgeDataDiv {
//     padding: 10px;
//     border-radius: 3px;
//     height: auto;
//   }
// }

// .mainPropertyCard {
//   max-width: 384px;
//   position: relative;
//   margin-top: 1rem;
//   border-radius: 6px;
//   box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 #00000028;
// }
// /* .mainPropertyCardSecond{
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   margin-top: 1rem;
// } */
// /* .mainPropertySecondFirst {
//   display: flex;
//   width: 90%;
// } */
// /* .swiper-image {
//   width: 55%;
//   border-radius: 0.35rem !important;
// } */
// /* .main-image {
//   height: 20rem;
//   width: 100%;
//   object-fit: fill !important;
//   border-radius: 0.35rem !important;
// } */


// .swiper {
//   width: 100%;
//   height: 75%;
//   border-radius: 6px;
// }
// .main-video {
//   /* max-width: 20rem !important; */
//   height: 20rem;
//   width: 100%;
//   object-fit: cover !important;
//   border-radius: 0.35rem !important;
// }



// .mainPropertyCardFirst {
//   margin-bottom: 15px;
// }
// .topTags{
//   font-size: 1.3rem;
//   display: flex;
//   gap: 10px;
  
// }
// .main-image {
//   border-radius: 6px;
// }
// .swiper-image {
//   width: 100%;
//   height: 20rem;
//   border-radius: 6px;
// }
// .mainPropertyCardFirst {
//   position: absolute;
//   top: 15px;
//   left: 15px;
//   z-index: 9;
// }

// .property-list-div {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   align-items: center;
// }

// p.property-price {
//   margin-bottom: 0;
//   font-weight: 700;
//   font-size: 1.65rem;
//   /* color: var(--dark-color); */
//   color: #15803d;
// }
// .property-footer {
//   padding: 1rem;
//   display: flex;
//   justify-content: flex-end;
//   background-color: #f9fafb;
//   /* align-self: ; */
// }
// .property-details {
//   padding: 15px 15px 0px;
//   border-bottom: 1px solid var(--gray-divider);
//   /* margin-bottom: 20px; */
//   margin-top: -70px;
//   /* position: absolute; */
// }
// .property-description p {
//   font-weight: 300;
//   font-size: 16px;
//   font-family: "Inter", sans-serif;
//   line-height: 155%;
//   margin-top: -5px;
// }
// .actions-btn {
//   display: inline-flex;
//   gap: 10px;
// }
// .actions-btn .btn {
//   padding: 10px 12px;
//   height: auto;
// }

// .actions-btn .btn i {
//   padding-right: 0px;
// }
