import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form} from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";


const LandTypeItems = () => {
  const loggedInUser     = useSelector((state) => state.auth.user);
  const { landTypeId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = loggedInUser  ?.token;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${baseUrl}/property/landType/${landTypeId}`, { 
          headers:{
            Authorization: `Bearer ${token}`,
          }
        });
        if (response.status === 200) {
          const landTypeData = response.data.landType;
          setFormData({
            name: landTypeData.name,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);

  
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Land Types", link: "/land-types" },
    { label: "Edit Land Type", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit Land Type Details"
              description="Edit land type details section"
            />
            <Form>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                    />
                  </div>
                </Col>
              </Row>

              
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-5"
                to={"/land-types"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LandTypeItems;