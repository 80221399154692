import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Select from "react-select";
import Tr from "../../components/tr/Tr";



const PropertySubSubType = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;

  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedSubType, setSelectedSubType] = useState(null);
  const [propertyTypeData, setPropertyTypeData] = useState([]);
  const [subTypeData, setSubTypeData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const propertyTypeApi = Api({ url: `property/types` });
  const subTypeApi = Api({ url: `property/sub/types/${selectedPropertyType?.value}` });
  const subSubTypeApi = Api({ url: `property/sub/sub/types/${selectedSubType?.value}` });

  const initialValues = {};
  // Fetch Property Types
  useEffect(() => {
    const fetchPropertyTypes = async () => {
      try {
        const response = await propertyTypeApi.fetchData();
        const propertyTypes = response.data.propertyTypes.map((type) => ({
          label: type.name,
          value: type.id,
        }));
        setPropertyTypeData(propertyTypes);
      } catch (error) {
        console.error("Error fetching property types:", error);
      }
    };
    fetchPropertyTypes();
  }, []);

  // Fetch Sub Types based on selected Property Type
  useEffect(() => {
    if (!selectedPropertyType) return;
    const fetchSubTypes = async () => {
      try {
        const response = await subTypeApi.fetchData();
        const subTypes = response.data.propertySubTypes.map((subType) => ({
          label: subType.name,
          value: subType.id,
        }));
        setSubTypeData(subTypes);
      } catch (error) {
        console.error("Error fetching sub types:", error);
      }
    };
    fetchSubTypes();
  }, [selectedPropertyType]);

  // Fetch Sub Sub Types based on selected Sub Type
  useEffect(() => {
    if (!selectedSubType) return;
    const fetchSubSubTypes = async () => {
      try {
        const response = await subSubTypeApi.fetchData(currentPage);
        setData(response.data.propertySubSubTypes);
        setTotalPages(response.data.totalPage);
      } catch (error) {
        console.error("Error fetching sub sub types:", error);
      }
    };
    fetchSubSubTypes();
  }, [selectedSubType, currentPage]);

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/sub/sub/type/delete/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Sub Sub Type deleted successfully", { type: "success" });
      } else {
        console.error("Error deleting item:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Name", key: "name" },
    { displayName: "Description", key: "description" },
  ];
  const breadcrumbItems = [
    { label: "Home", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/master" },
    { label: "Property Sub Sub Types", link: "/property-sub-sub-type", active: true },
  ];
  return (
    <>
      <Notification />
      <div >
        <Container>
          <BreadCrumb items={breadcrumbItems} />
          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12} style={{ minHeight: "50vh" }}>
              <Container>
                <Row className="d-flex">
                  <Col lg={6} md={6} sm={12}>
                    <div className="mb-3">
                      <label htmlFor="propertyType" className="form-label">
                        Select Property Type
                      </label>
                      <Select
                        options={propertyTypeData}
                        onChange={setSelectedPropertyType}
                        value={selectedPropertyType}
                        placeholder="Select Property Type"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 999999999,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className="mb-3">
                      <label htmlFor="subType" className="form-label">
                        Select Property Sub Type
                      </label>
                      <Select
                        options={subTypeData}
                        onChange={setSelectedSubType}
                        value={selectedSubType}
                        placeholder="Select Property Sub Type"
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 999999999,
                          }),
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
              {(() => {
                if (selectedSubType === null) {
                  return (
                    <Col>
                      <Container>
                        <p>Please Select the Sub Type to Show the Sub Sub Type...</p>
                      </Container>
                    </Col>
                  );
                } else if (data?.length === 0) {
                  return (
                    <Tr
                      columns={columns}
                      initialValues={initialValues}
                      title="No Sub Sub Type Found"
                      buttonName="Create"
                      btnLink="/property-sub-sub-type/add-property-sub-sub-type"
                      titleHeader="Property Sub Sub Types"
                    />
                  );
                } else {
                  return (
                    <div className="mb-5">
                      <List
                        title="Property Sub Sub Type"
                        data={data}
                        columns={columns}
                        button="Create"
                        route="property-sub-sub-type"
                        to="/property-sub-sub-type/add-property-sub-sub-type"
                        handleDelete={handleDelete}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                      />
                    </div>
                  );
                }
              })()}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PropertySubSubType;
