import React, { useState, useEffect } from "react";
import Api from "../../../admin/components/api/Api";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../../admin/components/singleDetail/SingleDetailHeader";
import axios from "axios";
import BreadCrumb from "../../../admin/components/breadcrumb/BreadCrumb";
import AdminHeader from "../../../admin/common/Header";
import * as Yup from "yup";

const AddContact = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const userId = loggedInUser?.userDetails?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [allPurpose, setAllPurpose] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPurpose, setSelectedPurpose] = useState({});
  const [areaInput, setAreaInput] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showAreaInput, setShowAreaInput] = useState(false);
  const [newArea, setNewArea] = useState("");
  const addPostApi = Api({
    url: `user/contact/add`,
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    address: "",
    address1: "",
    area_name: "",
    street: "",
    apartment: "",
    zip_code: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await axios.post(
          `${baseUrl}/admin/countries`,
          {
            page_number: 1,
            page_size: 20,
            order_by: "ASC",
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };
        const fetchArea = async (cityId) => {
          if (!cityId) {
            setCities([]);
            return;
          }
          try {
            console.log("your city id ", cityId);
            const response = await axios.post(
              `${baseUrl}/admin/areas/${cityId}`,
              {
                page_number: 1,
                page_size: 20,
                order_by: "ASC",
              },
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              const areaData = response.data.areas.map((area) => ({
                label: area.name,
                value: area.id,
              }));
              setAreaInput([{ label: "Other", value: "other" }, ...areaData]);

              console.log(response.data.areas);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
        fetchArea(selectedCity);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    selectedArea,
    loggedInUser?.token,
  ]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      console.log(selectedState);
      const state = await states.filter((data) => data.id === +selectedState);
      const city = await cities.filter((data) => data.id === +selectedCity);
      const area = await areaInput.filter(
        (data) => data.value === +selectedArea.value
      );

      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        email_address: values.email_address,
        // address: values.address,
        area_name: values.area_name,
        // area_name: area[0].label,
        // address1: values.address1,
        // street: values.street,
        // apartment: values.apartment,
        country_name: values.country,
        state_name: state[0].name,
        city_name: city[0].name,
        zip_code: values.zip_code,
      };

      const responseData = await addPostApi.postData(data);
      console.log(responseData);
      if (responseData.data.statusType === "SUCCESS") {
        showToast("Contact added successfully", {
          type: "success",
        });

        setTimeout(() => {
          navigate("/contact");
        }, 3000);
      }
      console.log(data);

      //   console.log(data);
    } catch (error) {
      setIsError(true);
      console.log(error);
      showToast("Error Occured", {
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

        const validationSchema = Yup.object({
     mobile_number: Yup.string()
      .matches(
        /^(?:\+91|91)?[6-9]\d{9}$/,
        "Please enter a valid mobile number"
      )
      .required("Mobile Number is required"),
      });
  

  const handleAddArea = async () => {
    try {
      setIsLoading(true);
      const authToken = await loggedInUser?.token;
      const response = await axios.post(
        `${baseUrl}/admin/areas`,
        {
          name: newArea,
          city: {
            id: +selectedCity,
          },
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      if (response.data.statusType === "SUCCESS") {
        showToast("Area added successfully", { type: "success" });
        setShowAreaInput(false);
        setNewArea("");
        setSelectedArea({ label: newArea, value: response.data.area.id });
      } else {
        showToast("Error adding area", { type: "error" });
      }
    } catch (error) {
      showToast("Error adding area", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Customer Management", link: "/contact" },
    { label: "Add Customer", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
          <AdminHeader/>
          </Col>
        <Col lg={9} md={8} sm={12}>
        <SingleDetailHeader
          header="Add Customer"
          description="Add the contact details section"
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">
                      First Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="first_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter First Name"
                      name="first_name"
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="last_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter Last Name"
                      name="last_name"
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <Field
                      type="email"
                      className="form-control"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      name="email_address"
                    />
                    <ErrorMessage
                      name="email_address"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="mobile_number" className="form-label">
                      Mobile Number
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="mobile_number"
                      aria-describedby="emailHelp"
                      placeholder="Enter Phone Number"
                      name="mobile_number"
                    />
                    <ErrorMessage
                      name="mobile_number"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      Country
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="country"
                      required=""
                    >
                      <option value="">-- Select Country --</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      State
                    </label>
                    <select
                      className="form-control"
                      name="state"
                      required=""
                      onChange={(e) => setSelectedState(e.target.value)}
                    >
                      <option value="">-- Select State --</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="">
                    <label htmlFor="title" className="form-label">
                      City
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="city"
                      required=""
                      onChange={(e) => setSelectedCity(e.target.value)}
                    >
                      <option value="">-- Select City --</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Col>
                <Col>
                  {/* <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Area
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="last_name"
                      aria-describedby="emailHelp"
                      placeholder="Enter Address"
                      name="address"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="text-danger"
                    />
                  </div> */}
                  <Col>
                    {/* <div className="">
                      <label htmlFor="title" className="form-label">
                        Area
                      </label>
                      <Select
                        options={areaInput}
                        onChange={(option) => {
                          if (option.value === "other") {
                            setShowAreaInput(true);
                            setSelectedArea(option);
                          } else {
                            setSelectedArea(option);
                            setShowAreaInput(false);
                          }
                        }}
                        value={selectedArea}
                        placeholder="Select Area"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 999999,
                          }),

                          valueContainer: (provided) => ({
                            ...provided,
                            padding: "10px",
                          }),
                        }}
                      />
                      {showAreaInput && (
                        <div className="mt-3">
                          <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Enter new area"
                            value={newArea}
                            onChange={(e) => setNewArea(e.target.value)}
                          />
                          <Button
                            variant="primary"
                            onClick={handleAddArea}
                            disabled={isLoading || !newArea}
                          >
                            {isLoading ? "Adding..." : "Add Area"}
                          </Button>
                          <Button
                            style={{ marginLeft: "1rem" }}
                            variant="secondary"
                            onClick={() => setShowAreaInput(false)}
                            className="ml-2"
                          >
                            Cancel
                          </Button>
                        </div>
                      )}
                    </div> */}

                    <div className="mb-3">
                      <label htmlFor="zip_code" className="form-label">
                        Area
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="area_name"
                        aria-describedby="emailHelp"
                        placeholder="Enter Area"
                        name="area_name"
                      />
                      <ErrorMessage
                        name="area_name"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </Col>
                </Col>

                <Col>
                  <div className="mb-3">
                    <label htmlFor="zip_code" className="form-label">
                      Zip Code
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="zip_code"
                      aria-describedby="emailHelp"
                      placeholder="Enter Zip Code"
                      name="zip_code"
                    />
                    <ErrorMessage
                      name="zip_code"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                {/* <Col>
                  <div className="mb-3">
                    <label htmlFor="apartment" className="form-label">
                      Apartment
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="apartment"
                      aria-describedby="emailHelp"
                      placeholder="Enter Apartment Name"
                      name="apartment"
                    />
                    <ErrorMessage
                      name="apartment"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <label htmlFor="street" className="form-label">
                      Street
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="street"
                      aria-describedby="emailHelp"
                      placeholder="Enter Street"
                      name="street"
                    />
                    <ErrorMessage
                      name="street"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Col> */}
              </Row>
              <Row className="mb-3"></Row>

              {/* <Col>
                <div className="mb-3">
                  <label htmlFor="address1" className="form-label">
                    Address 1
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    id="address1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Address"
                    name="address1"
                  />
                  <ErrorMessage
                    name="address1"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </Col> */}
              <button
                type="submit"
                className="btn btn-primary mt-5"
                style={{ marginBottom: "10rem" }}
                disabled={isSubmitting}
                // disabled={true}
              >
                {isSubmitting ? <Spinner loading={true} /> : "Add Contact "}
              </button>
            </Form>
          )}
        </Formik>
        </Col>

      
        </Row>
      
      </Container>
    </>
  );
};

export default AddContact;
