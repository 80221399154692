import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Alert,
  Badge,
  Dropdown,
  DropdownButton,
  Card,
} from "react-bootstrap";

import AdminHeader from "../../common/Header";
import Button from "../../../components/common/button/Button";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import List from "../../components/listing/List";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";

import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
import "./post.css";
import { FaEllipsisV, FaComment } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import PostSingleCard from "../../components/post/PostSingleCard";
import Pagination from "../../components/pagination/Pagination";
import PropertyLikePostCard from "../../components/post/PropertyLikePostCard";
const Post = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const [assetUrl, setAssetUrl] = useState("");
  const propertyApi = Api({
    url: "property/leads",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      console.log("hello from delete", itemId);
      const response = await axios.delete(
        `${baseUrl}/admin/deleteDesiredRequest/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        handleDeleteAlertClose();
        showToast("Post deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "Description", key: "description" },
    { displayName: "Purpose", key: "purpose.name" },
  ];
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      const responseData = await propertyApi.fetchData(pageNumber);
      console.log(responseData);
      setData(responseData?.data?.leads);
      setAssetUrl(responseData?.data?.assetUrl);
      setTotalPages(responseData.data.totalPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Posts", link: "/post" },
  ];
  const uniqueLocations = new Set();

  return (
    <>
      <Notification />
      <div className="bread-crumb">
        <Container>
          <Col md={8}>
            <BreadCrumb items={breadcrumbItems} />
          </Col>
        </Container>
      </div>
      <Container>
        <div className="">
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row className="mt-3">
            {/* <Col
              md={8}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <BreadCrumb items={breadcrumbItems} />
            </Col> */}
            {/* <Col md={2}>
              <AdminHeader />
            </Col> */}
            <Col md={12} className="mb-5">
              {data && data.length === 0 ? (
                <>
                  <Tr
                    columns={columns}
                    initialValues={initialValues}
                    title="No Post Found"
                    buttonName="Create"
                    btnLink="/post/add-post"
                    titleHeader="Posts"
                  />
                </>
              ) : (
                <>
                  {/* <Row> */}
                  <Col md={12}>
                    <Row>
                      <Col md={10}>
                        <h2>Posts</h2>
                      </Col>
                      <Col md={2} className="text-lg-end">
                        {/* {console.log(userType)} */}
                        {/* {userType === "SUPER_ADMIN" ||
                          userType === "ALL_HOME_DESIRE" ? (
                            <></>
                          ) : (
                            <Link
                              className="btn btn-primary"
                              to={"/post/add-post"}
                            >
                              Create
                            </Link>
                          )} */}
                        <Link className="btn btn-primary" to={"/post/add-post"}>
                          Create
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                  {/* </Row> */}
                  {/* <PostSingleCard data={data} handleDelete={handleDelete} /> */}
                  <Row>
                    <PropertyLikePostCard data={data} assetUrl={assetUrl} />
                    {/* </Col> */}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </>
  );
};

export default Post;
