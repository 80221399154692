import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Alert, InputGroup } from "react-bootstrap";
import { FaLock } from "react-icons/fa"; // Lock icon
import forgotPasswordImg from "../assets/images/Pass.png"


export default function EmailScreen() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendOTP = async () => {
    if (email && isValidEmail(email)) {
      try {
        const response = await fetch(
        `${baseUrl}/all/forgot-password?email=${email}`,
          {
            method: "POST",
          }
        );
        const data = await response.json();

        if (response.ok && data.statusType === "SUCCESS") {
          setSuccess("OTP sent successfully! Check your email.");
          setError("");
          setTimeout(() => navigate("/OTP-Screen", { state: { email } }), 2000);
        } else {
          setError(data.text || "Failed to send OTP. Please try again.");
          setSuccess("");
        }
      } catch (error) {
        setError("Error sending OTP: " + error.message);
        setSuccess("");
      }
    } else {
      setError("Please enter a valid email.");
      setSuccess("");
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px",maxHeight:"800px" }}>
      {/* Image with margin */}
      <div className="text-left mb-4">
        <img 
          src={forgotPasswordImg} 
          alt="Forgot Password" 
          style={{ width: "100%", maxWidth: "200px"}} 
        />
      </div>

      <h2 className="text-left">Forgot <br/> Password?</h2>

      <p className="text-left" style={{ color: "black", fontSize: "12px" }}>
        Don't worry! It happens. Please enter the email address associated with your account.
      </p>

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form>
        <Form.Group className="mb-3">
          {/* <Form.Label>Email address</Form.Label> */}
          <InputGroup>
            {/* Lock Icon */}
            <InputGroup.Text>
              <FaLock />
            </InputGroup.Text>
            <Form.Control
              type="email"
              placeholder="Enter Email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </Form.Group>

        {/* Submit button with margin below */}
        <Button 
          variant="primary"  
          className="btn btn-primary w-100 mb-3" 
          onClick={handleSendOTP}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
}
