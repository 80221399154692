import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/common/spinner/Spinner";
import { useSelector } from "react-redux";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
const EditLandType = () => {
  const loggedInUser     = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { landTypeId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = loggedInUser  ?.token;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${baseUrl}/property/landType/${landTypeId}`, { 
          headers:{
            Authorization: `Bearer ${token}`,
          }
        });
        if (response.status === 200) {
          const landTypeData = response.data.landType;
          setFormData({
            name: landTypeData.name,
          });
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
        showToast("Error Occurred", { type: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const data = {
        id: landTypeId,
        name: formData.name,
      };
      console.log(data);
  
      const response = await axios.post(`${baseUrl}/property/editLandType`, data, {
        headers:{
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.status === 200) {
        showToast("Land Type updated successfully", { type: "success" });
        setTimeout(() => {
          navigate("/land-types");
        }, 3000);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      showToast("Error Occurred", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Land Types", link: "/land-types" },
    { label: "Edit Land Type", link: "", active: true },
  ];
  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Edit Land Type Details"
              description="Edit land type details section"
            />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </Col>
              </Row>

              <Button variant="primary" type="submit" className="mt-5 mb-5">
                {isLoading ? <Spinner loading={true} /> : "Update"}
              </Button>
              <Link
                style={{ marginLeft: "1rem" }}
                className="btn btn-primary mb-5 mt-5"
                to={"/land-types"}
              >
                Back
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditLandType;