// import React from "react";
// import { Link } from "react-router-dom";

// import Container from "react-bootstrap/Container";
// import { Row } from "react-bootstrap";

// import "swiper/css";
// import Col from "react-bootstrap/Col";

// import cityImg1 from "../../assets/images/city-1.jpg";
// import cityImg2 from "../../assets/images/city-2.jpg";
// import cityImg3 from "../../assets/images/city-3.jpg";
// import cityImg4 from "../../assets/images/city-4.jpg";
// import PbcCard from "./cards/pbc/index";

// const PropertyByCity = () => {
//   return (
//     <>
//       <section className="property-by-city">
//         <Container>
//           <Row>
//             <Col md={12}>
//               <div className="section-title">
//                 <h3 className="wow fadeInUp">Location</h3>
//                 <h2 className="text-anime">Properties by Cities</h2>
//               </div>
//             </Col>
//           </Row>
//           <Row>
//             <Col md={6} lg={3}>
//               <PbcCard
//                 title="Greater Noida"
//                 properties="22 Properties"
//                 imageUrl={cityImg1}
//               />
//             </Col>
//             <Col md={6} lg={3}>
//               <PbcCard
//                 title="Greater Noida West"
//                 properties="22 Properties"
//                 imageUrl={cityImg2}
//               />
//             </Col>
//             <Col md={6} lg={3}>
//               <PbcCard
//                 title="Noida"
//                 properties="22 Properties"
//                 imageUrl={cityImg3}
//               />
//             </Col>
//             <Col md={6} lg={3}>
//               <PbcCard
//                 title="Meerut"
//                 properties="22 Properties"
//                 imageUrl={cityImg4}
//               />
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// };

// export default PropertyByCity;


import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PbcCard from "./cards/pbc/index";

import cityImg1 from "../../assets/images/city-1.jpg";
import cityImg2 from "../../assets/images/city-2.jpg";
import cityImg3 from "../../assets/images/city-3.jpg";
import cityImg4 from "../../assets/images/city-4.jpg";

const cities = [
  { name: "Anand Vihar", image: cityImg1 },
  { name: "Laxmi Nagar", image: cityImg2 },
  { name: "Ghaziabad", image: cityImg3 },
  { name: "Gautam Buddha Nagar", image: cityImg4 },
];

const PropertyByCity = () => {
  const [propertyCounts, setPropertyCounts] = useState({});
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchPropertyCounts = async () => {
      const counts = {};

      await Promise.all(
        cities.map(async (city) => {
          try {
            const response = await fetch(`${baseUrl}/all/property/count`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ city: city.name }),
            });

            const data = await response.json();
            counts[city.name] = data.propertyCount  || "0"; // Default to "0" if no data
          } catch (error) {
            console.error(`Error fetching data for ${city.name}:`, error);
            counts[city.name] = "0"; // Handle error gracefully
          }
        })
      );

      setPropertyCounts(counts);
    };

    fetchPropertyCounts();
  }, []);

  return (
    <section className="property-by-city">
      <Container>
        <Row>
          <Col md={12}>
            <div className="section-title">
              <h3 className="wow fadeInUp">Location</h3>
              <h2 className="text-anime">Properties by Cities</h2>
            </div>
          </Col>
        </Row>
        <Row>
          {cities.map((city, index) => (
            <Col key={index} md={6} lg={3}>
              <PbcCard
                title={city.name}
                // properties={`${propertyCounts[city.name] || "Loading..."} Properties`}
                properties={propertyCounts[city.name] || "Loading..."}

                imageUrl={city.image}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PropertyByCity;
