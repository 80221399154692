import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {Container,Row,Col,Badge,Dropdown,DropdownButton,} from "react-bootstrap";
import Api from "../../components/api/Api";
import { Link, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Style from "../../UserStyle.module.css";
import parse from "html-react-parser";
import Styles from "./Property.module.css";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import { useSelector } from "react-redux";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import PropertyComment from "./PropertyComment";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import {FaEllipsisV,FaComment,FaCheckCircle,FaBed,FaBath,FaRestroom,} from "react-icons/fa";
import Notification, {showToast,} from "../../../components/common/notification/Notification";
import axios from "axios";
import TabMenu from "../homepage/TabMenu";
import PropertyNotes from "../../components/notes/PropertyNotes";

const PropertyItem = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [singleData, setsingleData] = useState({});
  const [assetUrl, setAssetUrl] = useState("");
  const { propertyId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const singlePropertyData = Api({
    url: `property/propertyDetail/${propertyId}`,
  });
  const [leadId, setLeadId] = useState(1);
  const [Id, setId] = useState();
  
  useEffect(() => {
    try {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const responseData = await singlePropertyData.fetchDataBySingleId();
          console.log("your response data is", responseData);
          setPropertyDetails(responseData?.data?.propertyInfo);
          setsingleData(responseData?.data?.propertyInfo);
          setAssetUrl(responseData?.data?.assetUrl);
          setLeadId(responseData?.data?.propertyInfo?.lead_id);
          setId(responseData?.data?.propertyInfo?.id);

          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/deleteProperty/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("Property deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
    }
  };
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Properties", link: "/property" },
    { label: "Property Details", link: "", active: true },
  ];

  const renderTrueFeatures = (features) => {
    const trueFeatures = Object.entries(features).filter(
      ([key, value]) =>
        value === true &&
        !["active", "deleted", "id", "created_on", "updated_on"].includes(key)
    );

    return trueFeatures.map(([key]) => (
      <Col md={4} key={key} className="d-flex align-items-center mb-3">
        <FaCheckCircle className="me-2" />
        <span>
          {key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())}
        </span>
      </Col>
    ));
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  console.log(singleData);
  // console.log("Fetched Property Data:", responseData?.data?.propertyInfo);

  return (
    <>
      {isLoading ? (
        <div className={Style.spinner}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div
            className="main-wrapper"
            style={
              {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "2rem",
              }
            }
          >
      <Container>
        <TabMenu />
          <Row className="mainCard mt-3">
            <Col lg={12} md={12} sm={12} className="mainCardAreaInside">
              <div className="card">
                <Row className="">
                  <Col
                    md="auto"
                    sm="auto"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    {/* <div className="profilePic">
                      <img src="https://plus.unsplash.com/premium_photo-1661964217492-70800dc09cac?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8fA%3D%3D" />
                    </div> */}
                  </Col>
                  <Col md={10} sm={8}>
                    <Col md={12}>
                      <div className="cardTop">
                        <div className="nameSection">
                          <p>{singleData?.user?.first_name}</p>
                        </div>
                        <div className="badgebootstrap">
                          {singleData?.purpose?.name === "Buy" ? (
                            <Badge
                              bg="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {singleData?.purpose?.name}
                            </Badge>
                          ) : singleData?.purpose?.name === "Sell" ? (
                            <Badge
                              bg="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {singleData?.purpose?.name}
                            </Badge>
                          ) : singleData?.purpose?.name === "Rent" ? (
                            <Badge
                              bg="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {singleData?.purpose?.name}
                            </Badge>
                          ) : singleData?.purpose?.name === "Investment" ? (
                            <Badge
                              bg="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {singleData?.purpose?.name}
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="badgebootstrap">
                          <Badge
                            bg="secondary"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            {singleData?.type?.name}
                          </Badge>
                        </div>
                        <div className="badgebootstrap">
                          <Badge
                            bg="secondary"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            {singleData?.property_sub_type?.name}
                          </Badge>
                        </div>

                        {singleData?.is_favorite && (
                          <div className="badgebootstrap">
                            <Badge
                              bg="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              {" "}
                              {singleData?.status?.name}
                            </Badge>
                          </div>
                        )}
                        <div className="badgebootstrap">
                          <Badge
                            bg="secondary"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            {singleData?.status?.name}
                          </Badge>
                        </div>

                        <div className="timeAgo">
                          <TimeAgo timestamp={singleData?.created_on} />
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col
                          md={10}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3>{singleData?.name}</h3>
                        </Col>

                      </Row>
                    </Col>
                    <Col md={12}>
                      <div className="mainDataDiv">
                        <div className="badgeDataDiv">
                          <div className="iconDataDiv">
                            <small>
                              <FaBed className="faBed" />
                            </small>
                          </div>
                          <div className="roomDataDiv">
                            <span className="small">
                              {singleData?.structure?.bed_rooms} bedrooms
                            </span>
                          </div>
                        </div>
                        <div className="badgeDataDiv">
                          <div className="iconDataDiv">
                            <small>
                              <FaBath className="faBed" />
                            </small>
                          </div>
                          <div className="roomDataDiv ">
                            <span className="small">
                              {singleData?.structure?.bath_rooms} bathrooms
                            </span>
                          </div>
                        </div>
                        <div className="badgeDataDiv">
                          <div className="iconDataDiv">
                            <small>
                            </small>
                          </div>
                          <div className="roomDataDiv ">
                            <span className="small">
                              {singleData?.structure?.rooms} rooms
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="slider-container">
                        <Swiper
                          spaceBetween={50}
                            breakpoints={{
                            576: {
                              width: 576,
                              slidesPerView: 2,
                            },
                            768: {
                              width: 768,
                              slidesPerView: 3,
                            },
                            1100: {
                              width: 768,
                              slidesPerView: 3,
                            },
                          }}
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          onSlideChange={() => console.log("slide change")}
                          onSwiper={(swiper) => console.log(swiper)}
                        >
                          {singleData?.images?.map((singleImage) => (
                            <SwiperSlide>
                              {singleImage?.image_url?.includes(".mp4") ? (
                                <video
                                  autoPlay
                                  muted
                                  loop
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "15rem",
                                    objectFit: "cover",
                                  }} // Optional styling for responsive videos
                                >
                                  <source
                                    src={`${assetUrl}/${singleImage.image_url}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video
                                  tag.
                                </video>
                              ) : (
                                <img
                                  src={`${assetUrl}/${singleImage.image_url}`}
                                  style={{
                                    height: "15rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    objectFit: "cover",
                                    borderRadius: "0.35rem",
                                  }}
                                />
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={12} className="mt-1">
                          <div className="allCard">
                            <Row>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>State: {singleData?.state_name} </p>
                                </div>
                              </Col>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>City: {singleData?.city_name}</p>
                                </div>
                              </Col>
                              <Col md={4} sm={6} className="mt-2">
                                <div className="smallCard">
                                  <p>Area: {singleData?.area_name}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={12} className="mt-3">
                          <p>
                            {showFullDescription
                              ? parse(
                                  `${singleData?.description}
                              `
                                )
                              : parse(
                                  `${singleData?.description?.substring(
                                    0,
                                    200
                                  )}
                            
                            `
                                )}
                          </p>
                          <Link
                            className="p-0 text-primary fw-bold"
                            onClick={toggleDescription}
                          >
                            {showFullDescription
                              ? "Read less"
                              : "Read more"}
                          </Link>
                        </Col>
                        <Col md={12} className="mt-3">
                          <Row>
                            {singleData?.feature &&
                              renderTrueFeatures(singleData.feature)}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col md={1} sm={2}>
                    {(singleData?.user?.id === userId ||
                      userType === "SUPER_ADMIN") && (
                      <div className="menu">
                        {/* <FaEllipsisV /> */}
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={<FaEllipsisV />}
                          variant="Secondary"
                          style={{ color: "#fff" }}
                        >
                          <>
                            <Dropdown.Item href="#/action-2">
                              <Link to={`/property/${singleData?.id}/edit`}>
                                Edit
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link
                                to={``}
                                onClick={() => handleDelete(singleData?.id)}
                              >
                                Delete
                              </Link>
                            </Dropdown.Item>
                          </>
                        </DropdownButton>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={12} md={12} sm={12}>
              <Row style={{ marginTop: "2rem" }}>
                <div>
                  {userType === "PARTNER" || userType === "CUSTOMER" ? (
                    <Link
                      className="btn btn-primary"
                      to={"/dashboard/myspace"}
                    >
                      Back
                    </Link>
                  ) : (
                    <Link
                      className="btn btn-primary"
                      to={"/dashboard/myspace"}
                    >
                      Back
                    </Link>
                  )}
                </div>
              </Row>
            </Col>
            {/* <Col md={12} className="mt-3 mb-3">
              {leadId && <PropertyComment leadId={leadId} />}
            </Col> */}
            <Col lg={12} md={12} sm={12}>
              <div className="card mt-3 mb-3 shadow-sm">
                <div className="card-body">
                  <Row className="justify-content-center">
                    {/* <PropertyComment leadId={leadId}/> */}
                    <PropertyComment Id={Id}/>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {(userType === "PARTNER" || userType === "ALL_HOME_DESIRE") && (
            <Row> <PropertyNotes/></Row>
          )}
          
      </Container>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyItem;
