import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Notification, { showToast } from "../../../components/common/notification/Notification";
import Spinner from "../../../components/common/spinner/Spinner";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import AdminHeader from "../../common/Header";
import Select from "react-select";

const AddPropertySubSubType = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;
  const navigate = useNavigate();
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertySubTypes, setPropertySubTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const initialValues = {
    name: "",
    description: "",
    property_type_id: "",
    property_sub_type_id: "",
  };

  // Fetch Property Types
  useEffect(() => {
    const fetchPropertyTypes = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/property/types`,
          { page_number: 1, page_size: 20, order_by: "ASC" },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.statusType === "SUCCESS") {
          const formattedData = response.data.propertyTypes.map((type) => ({
            label: type.name,
            value: type.id,
          }));
          setPropertyTypes(formattedData);
        } else {
          setPropertyTypes([]);
        }
      } catch (error) {
        console.error("Error fetching property types:", error);
      }
    };

    fetchPropertyTypes();
  }, [token]);

  // Fetch Property Sub Types (when Property Type is selected)
  useEffect(() => {
    if (!selectedPropertyType) return;

    const fetchPropertySubTypes = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/property/sub/types/${selectedPropertyType.value}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.data.statusType === "SUCCESS") {
          const formattedData = response.data.propertySubTypes.map((subType) => ({
            label: subType.name,
            value: subType.id,
          }));
          setPropertySubTypes(formattedData);
        } else {
          setPropertySubTypes([]);
        }
      } catch (error) {
        console.error("Error fetching property sub types:", error);
      }
    };

    fetchPropertySubTypes();
  }, [selectedPropertyType, token]);

  // Handle Form Submission
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const data = {
        name: values.name.toUpperCase(),
        description: values.description,
        property_type_id: values.property_type_id,
        property_sub_type_id: values.property_sub_type_id,
      };

      const response = await axios.post(`${baseUrl}/property/sub/sub/type/add`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.statusType === "SUCCESS") {
        showToast("Property Sub-Sub Type added successfully!", { type: "success" });

        setTimeout(() => {
          navigate("/property-sub-sub-type");
        }, 3000);
      }
    } catch (error) {
      console.error("Error adding property sub-sub type:", error);
      showToast("Error occurred while adding property sub-sub type", { type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Sub-Sub Types", link: "/property-sub-sub-type" },
    { label: "Add Property Sub-Sub Type", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Add Property Sub-Sub Type"
              description="Enter details to add a new property sub-sub type"
            />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ isSubmitting, setFieldValue }) => (
                <Form className="mb-4">
                  {/* Select Property Type */}
                  <div className="mb-3">
                    <label className="form-label">Property Type</label>
                    <Select
                      options={propertyTypes}
                      onChange={(option) => {
                        setSelectedPropertyType(option);
                        setFieldValue("property_type_id", option.value);
                        setPropertySubTypes([]); // Reset sub-types when type changes
                      }}
                      placeholder="Select Property Type"
                    />
                    <ErrorMessage name="property_type_id" component="div" className="text-danger" />
                  </div>

                  {/* Select Property Sub Type */}
                  <div className="mb-3">
                    <label className="form-label">Property Sub Type</label>
                    <Select
                      options={propertySubTypes}
                      onChange={(option) => setFieldValue("property_sub_type_id", option.value)}
                      placeholder="Select Property Sub Type"
                      isDisabled={!selectedPropertyType}
                    />
                    <ErrorMessage name="property_sub_type_id" component="div" className="text-danger" />
                  </div>

                  {/* Enter Name */}
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <Field type="text" className="form-control" name="name" placeholder="Enter name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>

                  {/* Enter Description */}
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <Field type="text" className="form-control" name="description" placeholder="Enter Description" />
                    <ErrorMessage name="description" component="div" className="text-danger" />
                  </div>

                  {/* Submit Button */}
                  <button type="submit" className="btn btn-primary mt-4" disabled={isSubmitting}>
                    {isSubmitting ? <Spinner loading={true} /> : "Add Property Sub-Sub Type"}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddPropertySubSubType;
