import React from "react";

import { Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetForm } from "../../../features/formData/formSlice";

const TabMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Array of valid paths
  const validPaths = [
    "/dashboard/preferred",
    "/dashboard/myspace",
    "/dashboard/addPostDashboard",
    "/dashboard/addPropertyDashboard",
    "/dashboard/promotional",
  ];

  // Default to "Search" if the current path is not valid
  const currentPath = validPaths.includes(location.pathname)
    ? location.pathname
    : "/dashboard/preferred"; // Default path set to "Search"

  const handleAddPropertyClick = () => {
    dispatch(resetForm());
  };

  return (
    <Nav
      variant="tabs"
      activeKey={currentPath} // Set the active key based on the current path
      onSelect={(selectedKey) => navigate(selectedKey)} // Navigate on tab selection
    >
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={"/dashboard"}
          active={location.pathname === "/dashboard"}
        >
          Search
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link
          as={Link}
          to={"/dashboard/preferred"}
          active={location.pathname === "/dashboard/preferred"}
        >
          Preferred
        </Nav.Link>
      </Nav.Item> */}
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={"/dashboard/myspace"}
          active={location.pathname === "/dashboard/myspace"}
        >
          My Space
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={"/dashboard/addPostDashboard"}
          active={location.pathname === "/dashboard/addPostDashboard"}
        >
          Add Post
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link
          as={Link}
          to={"/dashboard/addPropertyDashboard"}
          active={location.pathname === "/dashboard/addPropertyDashboard"}
        >
          Add Property
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="/dashboard/promotional">Promotional</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default TabMenu;
