import React, { useState, useEffect } from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
import Style from "../../UserStyle.module.css";
import { useParams } from "react-router-dom";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import TimeAgo from "../../components/timeAgo/TimeAgo";
import axios from "axios";

const PostComment = () => {
  const { postId: leadId } = useParams();
  const [allComment, setAllComment] = useState([]);
  const [comment, setComment] = useState("");
  const [activeReplyCommentIds, setActiveReplyCommentIds] = useState([]);
  const [replyText, setReplyText] = useState({});
  const loggedInUser = useSelector((state) => state.auth.user);
  const userDetails = loggedInUser?.userDetails;
  const userId = loggedInUser?.userDetails?.id;
  const userType = loggedInUser?.userDetails?.userType;
  const token = loggedInUser?.token;
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    fetchAllComment();
  }, []);

  const getAllCommentApi = Api({
    url: `property/requestComments/${userId}/${leadId}`,
  });

  const fetchAllComment = async () => {
    try {
      const fetchData = await getAllCommentApi.fetchData();
      setAllComment(fetchData?.data.requestComments || []);
    } catch (error) {
      console.log(error);
    }
  };

  const addPropertyComment = Api({
    url: `property/addRequestComment`,
  });

  const handleAddComment = async () => {
    const data = {
      comment: comment,
      user: {
        id: userId,
      },
      desired_request: {
        id: +leadId,
      },
    };

    try {
      const sendData = await addPropertyComment.postData(data);
      setComment("");

      if (sendData.data.statusType === "SUCCESS") {
        showToast("Comment added successfully", { type: "success" });
        const newComment = {
          id: sendData.data?.requestComment?.id,
          comment: comment,
          user: {
            id: userId,
            first_name: userDetails?.first_name,
            last_name: userDetails?.last_name,
          },
          created_on: new Date().toISOString(),
          children: [],
        };

        setAllComment([...allComment, newComment]);

        fetchAllComment();
      }
    } catch (error) {
      console.log(error);

    }
  };

  const deleteComment = async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/property/deleteRequestComment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data.statusType === "SUCCESS") {
        showToast("Comment deleted successfully", { type: "success" });
        const updatedComments = allComment.filter(
          (comment) => comment?.id !== id
        );

        setAllComment(updatedComments);
        fetchAllComment();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditComment = (commentId, initialComment) => {
    setEditingCommentId(commentId);
    setEditedComment(initialComment);
  };

  const handleSaveEditedComment = async () => {
    try {
      const data = {
        id: editingCommentId,
        comment: editedComment,
      };

      const response = await axios.put(
        `${baseUrl}/property/editRequestComment`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusType === "SUCCESS") {
        showToast("Comment edited successfully", { type: "success" });
        const updatedComments = allComment.map((comment) =>
          comment?.id === editingCommentId
            ? { ...comment, comment: editedComment }
            : comment
        );
        setAllComment(updatedComments);
        fetchAllComment();
      }

      setEditingCommentId(null);
      setEditedComment("");
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddReply = async (commentId) => {
    try {
      const data = {
        comment: replyText[commentId] || "",
        user: {
          id: userId,
        },
        desired_request: {
          id: +leadId,
        },
        parent_id: commentId,
      };

      const response = await addPropertyComment.postData(data);

      if (response.data.statusType === "SUCCESS") {
        showToast("Reply added successfully", { type: "success" });

        const updatedComments = allComment.map((comment) =>
          comment?.id === commentId
            ? {
                ...comment,
                children: [...comment.children, response.data.comment],
              }
            : comment
        );

        setAllComment(updatedComments);
        setReplyText((prev) => ({ ...prev, [commentId]: "" }));
        setActiveReplyCommentIds((prev) =>
          prev.filter((id) => id !== commentId)
        );

        fetchAllComment();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleReplyTextArea = (commentId) => {
    setActiveReplyCommentIds((prevState) =>
      prevState.includes(commentId)
        ? prevState.filter((id) => id !== commentId)
        : [...prevState, commentId]
    );
    setReplyText((prev) => ({ ...prev, [commentId]: "" }));
  };
  console.log("lead id is ",leadId)
  console.log("user id  is ",userId)


  const renderComments = (comments, depth = 0) => {
    return comments.map((singleComment) => (
      <Row key={singleComment?.id}>
        <Col md={12}>
          <div
            className={`${Style.loopingCommentSectionCard} ${Style[`depth${depth}`]}`}
          >
            <div className={Style.firstSection}>
              <div className={Style.profileSection}>
                <img
                  src="https://cdn-icons-png.freepik.com/256/1077/1077114.png?ga=GA1.1.266971223.1704785656&semt=ais_hybrid"
                  alt="Profile"
                  className={Style.profileSectionImg}
                />
                <div className={Style.profileSectionDetail}>
                  <span className={Style?.name}>
                    {singleComment?.user?.first_name} {singleComment?.user?.last_name}
                  </span>
                  <small className={Style.purpose1}>
                    {singleComment?.created_on && <TimeAgo timestamp={singleComment?.created_on} />}
                  </small>
                </div>
              </div>
              <div className={Style.profileSectionLast}>
              {(singleComment?.user?.id === userId || userType === "SUPER_ADMIN") && (
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                  <Dropdown.Menu>
                  
                      <>
                        <Dropdown.Item
                          onClick={() => handleEditComment(singleComment?.id, singleComment?.comment)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteComment(singleComment?.id)}>
                          Delete
                        </Dropdown.Item>
                      </>
               
                  </Dropdown.Menu>
                </Dropdown>
                     )}
              </div>
            </div>
            <div className={`${Style.secondSection} ${Style.renderingChild}`}>
              <div className={Style.messageDescription}>
                {editingCommentId === singleComment?.id ? (
                  <div className={Style.editableComment}>
                    <textarea
                      className={Style.textarea1}
                      value={editedComment}
                      onChange={(e) => setEditedComment(e.target.value)}
                    />
                    <button
                      className="btn btn-danger"
                      style={{ marginBottom: "1rem" }}
                      onClick={handleSaveEditedComment}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <p>{singleComment?.comment}</p>
                )}
              </div>
              <div className={Style.messageDescriptionReply}>
                {activeReplyCommentIds.includes(singleComment?.id) && (
                  <div>
                    <textarea
                      className={Style.textareaDescription}
                      placeholder="Reply"
                      value={replyText[singleComment?.id] || ""}
                      onChange={(e) =>
                        setReplyText((prev) => ({ ...prev, [singleComment?.id]: e.target.value }))
                      }
                    ></textarea>
                    <button
                      className={Style.addCommentBtn}
                      onClick={() => handleAddReply(singleComment?.id)}
                    >
                      Reply
                    </button>
                    <button
                      className={Style.addCommentBtn}
                      onClick={() => toggleReplyTextArea(singleComment?.id)}
                    >
                      Cancel
                    </button>
                  </div>
                )}
                {!activeReplyCommentIds.includes(singleComment?.id) && (
                  singleComment?.user?.id !== userId && (
                    <a className={Style.addCommentBtn} onClick={() => toggleReplyTextArea(singleComment?.id)}>
                    Reply
                  </a>
                  )
                
                )}
                {/* Render child comments recursively */}
                {singleComment?.children && renderComments(singleComment?.children, depth + 1)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    ));
  };

  return (
    <>
      <Notification />
      <Row>
        <Col lg={12}>
          <div className={Style.commentInput}>
            <textarea
              className={Style.textareaDescription}
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <button
              className={`${Style.addCommentBtn}`}
              onClick={handleAddComment}
            >
              Add Comment
            </button>
          </div>
        </Col>
        <Col md={12}>
          <div className={Style.commentsSection}>
            {renderComments(allComment)}
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PostComment;
