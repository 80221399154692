import React, { useState, useEffect } from "react";
import Api from "../../components/api/Api";
import { Container, Row, Col, Button } from "react-bootstrap";
import Notification, { showToast } from "../../../components/common/notification/Notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import AdminHeader from "../../common/Header";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import SingleDetailHeader from "../../components/singleDetail/SingleDetailHeader";
import SingleDetail from "../../components/singleDetail/SingleDetail";
import Spinner from "../../../components/common/spinner/Spinner";

const PropertySubSubTypeItem = () => {
  // Get user details from Redux
  const loggedInUser = useSelector((state) => state.auth.user);
  const token = loggedInUser?.token;

  // Get Property Sub Sub Type ID from URL
  const { propertySubSubTypeId } = useParams();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Local state
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({ name: "", description: "" });

  // Debugging Logs
  console.log("propertySubSubTypeId:", propertySubSubTypeId);

  useEffect(() => {
    const fetchSubSubTypeData = async () => {
      // Check if ID exists
      if (!propertySubSubTypeId) {
        console.error("propertySubSubTypeId is undefined! Cannot fetch data.");
        return;
      }
      // Check if token exists
      if (!token) {
        console.error("Token is missing. Redirecting to login.");
        navigate("/login");
        return;
      }

      try {
        setIsLoading(true);
        console.log("Fetching data from:", `${baseUrl}/property/sub/sub/type/${propertySubSubTypeId}`);

        const response = await axios.get(
          `${baseUrl}/property/sub/sub/type/${propertySubSubTypeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response?.data?.statusType === "SUCCESS") {
          console.log("API Response:", response);
          const postData = response.data.propertySubSubType;
          setFormData({ name: postData.name, description: postData.description });
        } else {
          console.error("API Response Error:", response.data);
          setIsError(true);
        }
      } catch (error) {
        console.error("API Request Failed:", error.response || error);
        if (error.response?.status === 401) {
          showToast("Unauthorized! Please log in again.", { type: "error" });
          navigate("/login");
        } else {
          showToast("Error Occurred", { type: "error" });
        }
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubSubTypeData();
  }, [propertySubSubTypeId, token, navigate]);

  // Breadcrumb Navigation
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },
    { label: "Property Sub Sub Types", link: "/property-sub-sub-type" },
    { label: "Property Sub Sub Type", link: "", active: true },
  ];

  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader
              header="Property Sub Sub Type Details"
              description="Property sub sub type details section"
            />

            {(() => {
              if (isLoading) {
                return <Spinner />;
              } else if (isError) {
                return <p className="text-danger">Failed to load data. Please try again.</p>;
              } else {
                return (
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <SingleDetail label="Name" userDetail={formData?.name} />
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <SingleDetail label="Description" userDetail={formData?.description} />
                      </div>
                    </Col>
                  </Row>
                );
              }
            })()}

            <div>
              <Button className="btn btn-primary mb-3 mt-3">
                <Link className="text-white" to={`/property-sub-sub-type/${propertySubSubTypeId}/edit`}>
                  Edit
                </Link>
              </Button>
              <Link style={{ marginLeft: "1rem" }} className="btn btn-primary mb-3 mt-3" to="/property-sub-sub-type">
                Back
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PropertySubSubTypeItem;
