import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import AdminHeader from "../../common/Header";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Spinner from "../../../components/common/spinner/Spinner";
import Error from "../../../components/common/error/Error";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Notification, {showToast,} from "../../../components/common/notification/Notification";
import FileUploadField from "../../../components/fileUpload/FIleUploadField";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setFormData,nextStep,prevStep,}from "../../../features/formData/formSlice";
import Api from "../../components/api/Api";

const AddPropertyDetail = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const formData = useSelector((state) => state.form.formData);
  const MAX_DESCRIPTION_LENGTH = 2000;
  const token = loggedInUser?.token;
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [propertyPurposes, setPropertyPurposes] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isAttachmentUploaded, setIsAttachmentUploaded] = useState(false);
  const [propertySubtypes, setPropertySubtypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [selectedPropertyPurpose, setSelectedPropertyPurpose] = useState("");
  const [characterCount, setCharacterCount] = useState(formData?.textData?.length || 0);
  const [uploadedImageNames, setUploadedImageNames] = useState([formData?.images,]);
  const [uploadedFilesNames, setUploadedFilesNames] = useState(formData?.files || []);
  const [selectedState, setSelectedState] = useState(formData?.state || "");
  const [selectedCountry, setSelectedCountry] = useState(formData?.country || "");
  const [textData, setTextData] = useState(formData?.textData || formData?.description || "");
  const [isError, setIsError] = useState(false);
  const [defaultStatusId, setDefaultStatusId] = useState(null);
  const initialValues = {
    price: formData?.price || "",
    name: formData?.name || "",
    featured_property: formData?.featured_property || "",
    status: formData?.status || defaultStatusId !== null ? defaultStatusId : "",
    country: formData?.country || "India",
    state: formData?.state || "",
    city: formData?.city || "",
    area: formData?.area || "",
    description: formData?.description || "Hello Write Your Description Here",
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    const state = states.find((state) => state.id === +values.state);
    const city = cities.find((city) => city.id === +values.city);
    const propertyType = propertyTypes.find(
      (type) => type.id === +values.propertyType
    );
    const propertyPurpose = propertyPurposes.find(
      (purpose) => purpose.id === +values.propertyPurpose
    );
    const statusLists = statusList.find(
      (status) => status.id === +values.status
    );

    const formDataInside = {
      ...formData,
      ...values,
      textData,
      propertyPurposeName: propertyPurpose,
      propertyTypeName: propertyType,
      statusTypeName: statusLists,
      stateName: state,
      cityName: city,
      countryName: "India",
      status: defaultStatusId,
    };

    dispatch(setFormData(formDataInside));
    dispatch(nextStep());
    setSubmitting(false);
  };
  const postData = {
    page_number: 1,
    page_size: 20,
    order_by: "ASC",
  };
  const fetchCountryResponse = Api({
    url: "/admin/countries",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token; // Add your authToken here
        const countryResponse = await fetchCountryResponse.fetchData();
        if (countryResponse.data.statusType === "SUCCESS") {
          const defaultCountry = countryResponse.data.countries.find(
            (country) => country.name === "India"
          );
          setCountries(countryResponse.data.countries);
          setSelectedCountry(defaultCountry ? defaultCountry.id : "");
        } else {
          setCountries([]);
        }

        const purposeResponse = await axios.post(
          `${baseUrl}/property/purposes`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (purposeResponse.data.statusType === "SUCCESS") {
          setPropertyPurposes(purposeResponse.data.propertyPurposes);
        } else {
          setPropertyPurposes([]);
        }

        const typeResponse = await axios.post(
          `${baseUrl}/property/types`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (typeResponse.data.statusType === "SUCCESS") {
          setPropertyTypes(typeResponse.data.propertyTypes);
        } else {
          setPropertyTypes([]);
        }

        const statusResponse = await axios.post(
          `${baseUrl}/property/statusList`,
          postData,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        if (statusResponse.data.statusType === "SUCCESS") {
          console.log(statusResponse.data.propertyStatuss);
          setStatusList(statusResponse.data.propertyStatuss);
          const newStatus = statusResponse.data.propertyStatuss.find(
            (status) => status.name === "New"
          );
          console.log("your default id is", newStatus);
          if (newStatus) {
            setDefaultStatusId(newStatus.id);
          }
        } else {
          setStatusList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await loggedInUser?.token;

        // Fetch states based on selected country
        const fetchStates = async (countryId) => {
          if (!countryId) {
            setStates([]);
            setIsCityDisabled(true);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/states/${countryId}`,
              postData,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setStates(response.data.states);

              setIsCityDisabled(false);
            } else {
              console.error("Error fetching states data:", response.data.text);
              setStates([]);
            }
          } catch (error) {
            console.error("Error fetching state list:", error);
            setStates([]);
            setIsCityDisabled(true);
          }
        };

        // Fetch cities based on selected state
        const fetchCities = async (stateId) => {
          if (!stateId) {
            setCities([]);
            return;
          }
          try {
            const response = await axios.post(
              `${baseUrl}/admin/cities/${stateId}`,
              postData,
              {
                headers: { Authorization: `Bearer ${authToken}` },
              }
            );
            if (response.data.statusType === "SUCCESS") {
              setCities(response.data.cities);
            } else {
              console.error("Error fetching cities data:", response.data.error);
              setCities([]);
            }
          } catch (error) {
            console.error("Error fetching cities data:", error);
            setCities([]);
          }
        };

        fetchStates(selectedCountry);
        fetchCities(selectedState);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedState, loggedInUser?.token]);
  useEffect(() => {
    const fetchSubtypes = async (propertyTypeId) => {
      if (!propertyTypeId) {
        setPropertySubtypes([]);
        return;
      }
      try {
        const response = await axios.post(
          `${baseUrl}/property/subtypes/${propertyTypeId}`,
          postData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          setPropertySubtypes(response.data.propertySubtypes);
        } else {
          setPropertySubtypes([]);
        }
      } catch (error) {
        console.error("Error fetching property subtypes:", error);
        setPropertySubtypes([]);
      }
    };

    fetchSubtypes(selectedPropertyType);
  }, [selectedPropertyType, token]);

  useEffect(() => {
    const fetchUserTypes = async (propertyPurposeId) => {
      if (!propertyPurposeId) return;

      try {
        const response = await axios.get(
          `${baseUrl}/user/types/${propertyPurposeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.statusType === "SUCCESS") {
          // Update your state or handle the response as needed
        } else {
          // Handle the failure
        }
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    fetchUserTypes(selectedPropertyPurpose);
  }, [selectedPropertyPurpose, token]);

  const validationSchema = Yup.object({
    // price: Yup.string().required("Price is required"),
    // name: Yup.string().required("Name is required"),
    // featured_property: Yup.string().required("Featured property is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    // area: Yup.string().required("Area is required"),
  });

  const handlePrevious = (values) => {
    const combinedFormData = {
      ...formData, // Previous form data
      ...values, // New values
      textData,
      status: defaultStatusId,
    };

    dispatch(setFormData(combinedFormData));
    // Navigate to the previous step
    dispatch(prevStep());
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            {/* <Row className="">
              <Col md={4}>
                <label htmlFor="price" className="form-label">
                  Budget
                </label>
                <Field
                  type="text"
                  className="form-control form-control-sm"
                  id="price"
                  placeholder="Enter Budget"
                  name="price"
                  style={{
                    height: "2.3rem",
                    padding: ".375rem 2.25rem .375rem .75rem",
                    backgroundColor: "#fff",
                  }}
                />
                <ErrorMessage
                  name="price"
                  component="div"
                  className="text-danger"
                />
              </Col>
              <Col md={4}>
                <label htmlFor="name" className="form-label">
                  Title
                </label>
                <Field
                  type="text"
                  className="form-control form-control-sm"
                  id="name"
                  placeholder="Enter Title"
                  name="name"
                  style={{
                    height: "2.3rem",
                    padding: ".375rem 2.25rem .375rem .75rem",
                    backgroundColor: "#fff",
                  }}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger"
                />
              </Col>
              <Col md={4}>
                <label htmlFor="featured_property" className="form-label">
                  New
                </label>
                <Field
                  as="select"
                  className="form-select"
                  id="featured_property"
                  name="featured_property"
                >
                  <option value="" label="Select option" />
                  <option value="true" label="Yes" />
                  <option value="false" label="No" />
                </Field>
                <ErrorMessage
                  name="featured_property"
                  component="div"
                  className="text-danger"
                />
              </Col>
              </Row> */}

            <div className="mt-3">
              {/* <div>
                <h6>Location</h6>
              </div> */}
              <div>
                <Row className="mb-3">
                  <Col md={4}>
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <Field
                      as="select"
                      className="form-select"
                      id="state"
                      name="state"
                      onChange={(e) => {
                        const stateId = e.target.value;
                        setFieldValue("state", stateId);
                        setSelectedState(stateId);
                        setFieldValue("city", "");
                        setCities([]);
                      }}
                      value={values.state}
                    >
                      <option value="">Select state</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="state"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <Field
                      as="select"
                      className="form-select"
                      id="city"
                      name="city"
                      disabled={isCityDisabled}
                      value={values.city}
                    >
                      <option value="">Select city</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="text-danger"
                    />
                  </Col>
                  {/* <Col md={4}>
                    <label htmlFor="area" className="form-label">
                      Area
                    </label>
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      id="area"
                      placeholder="Enter area"
                      name="area"
                      style={{
                        height: "2.3rem",
                        padding: ".375rem 2.25rem .375rem .75rem",
                        backgroundColor: "#fff",
                      }}
                    />
                    <ErrorMessage
                      name="area"
                      component="div"
                      className="text-danger"
                    />
                  </Col> */}
                </Row>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
             <ReactQuill
                theme="snow"
                value={textData}
                onChange={(value) => {
                  setTextData(value);
                  setCharacterCount(value.length);
                }}
              />
              <div className="mt-2">
                <span>
                  {characterCount}/{MAX_DESCRIPTION_LENGTH} characters
                </span>
                {characterCount > MAX_DESCRIPTION_LENGTH && (
                  <div className="text-danger">
                    Description exceeds the maximum length.
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex">
              {/* <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                onClick={() => handlePrevious(values)}
              >
                Previous
              </Button> */}
              <Button
                variant="primary"
                type="submit"
                  className="ms-auto"
                disabled={
                  isSubmitting || characterCount > MAX_DESCRIPTION_LENGTH
                }
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddPropertyDetail;
