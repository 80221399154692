import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import Api from "../../components/api/Api";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import UserProfile from "./UserProfile";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
const Profile = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const [userDetail, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const userType = loggedInUser?.userDetails?.userType;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [assetUrl, setAssetUrl] = useState("");

  const getDetailsApi = Api({
    url: `admin/user/id/${userId}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getDetailsApi.fetchData();
        console.log(responseData);
        setUserDetails(responseData?.data?.user);
        setAssetUrl(responseData.data.assetUrl); 
        console.log(responseData?.data?.user?.photo);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);
  };

  // const handleProfilePictureUpload = async () => {
  //   if (!profilePicture) {
  //     showToast("Please select a profile picture", {
  //       type: "error",
  //     });
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("file", profilePicture);
  //   formData.append("userId", +userId);

  //   console.log(profilePicture);
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };

  //   const response = await axios.put(
  //     `${baseUrl}/admin/changePicture`,
  //     formData,
  //     config
  //   );

  //   console.log("API Response:", response.data);
  //   if (response?.data?.statusType === "SUCCESS") {
  //     showToast("Profile Picture Uploaded successfully", {
  //       type: "success",
  //     });
  //   } else {
  //     showToast("Some Error Occured ! Please Try Again", {
  //       type: "error",
  //     });
  //   }
  // };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const responseData = await getDetailsApi.fetchData();
      setUserDetails(responseData?.data?.user);
      setAssetUrl(responseData.data.assetUrl);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  // Fetch data initially
  useEffect(() => {
    fetchData();
  }, []);
  

  const handleProfilePictureUpload = async () => {
  if (!profilePicture) {
    showToast("Please select a profile picture", { type: "error" });
    return;
  }

  const formData = new FormData();
  formData.append("file", profilePicture);
  formData.append("userId", +userId);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const response = await axios.put(
      `${baseUrl}/admin/changePicture`,
      formData,
      config
    );

    if (response?.data?.statusType === "SUCCESS") {
      showToast("Profile Picture Uploaded successfully", { type: "success" });

      // Fetch updated user details to reflect changes
      fetchData();
    } else {
      showToast("Some Error Occurred! Please Try Again", { type: "error" });
    }
  } catch (error) {
    console.error("Upload error:", error);
    showToast("Upload failed!", { type: "error" });
  }
};

  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Profile", link: "/user/profile", active: true },
  ];
  return (
    <>
      <div className="emp-profile">
        <Notification />
        <Container fluid style={{ marginTop: "2rem", padding: "0rem 4rem" }}>
          <BreadCrumb items={breadcrumbItems} />

          <Row>
            <Col md={3} style={{ marginLeft: "-8px" }}>
              <UserProfile />
            </Col>
            <Col md={8}>
              <form method="post" className="mb-5">
                <div className="row">
                  <div className="col-md-4">
                    <div className="profile-img">
                      {userDetail?.photo ? (
                        <img
                          src={`${assetUrl}${userDetail?.photo}?t=${new Date().getTime()}`}
                          alt=""
                          className="rounded-circle"
                        />
                      ) : (
                        <img
                          src={`https://cdn-icons-png.freepik.com/256/1077/1077114.png?ga=GA1.1.266971223.1704785656&semt=ais_hybrid`}
                          alt=""
                        />
                      )}
                      {userType === "SUPER_ADMIN" && (
                        <div className="file">
                          Change Photo
                          <input
                            name="file"
                            type="file"
                            id="profilePicture"
                            accept="image/*"
                            onChange={handleProfilePictureChange}
                          />
                          {uploading && <p>Uploading profile picture...</p>}
                          <button
                            type="button"
                            onClick={handleProfilePictureUpload}
                            disabled={uploading}
                            className="btn btn-danger btn-sm mt-2 "
                          >
                            Upload Profile Picture
                          </button>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="profile-head">
                      <h5>User</h5>
                    </div>
                    <hr />
                    <Row className="mt-3">
                      <Col md={12}>
                        <div
                          className="tab-content profile-tab"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Row>
                              <Col md={6}>
                                <label>Name</label>
                              </Col>
                              <Col md={6}>
                                <p>{userDetail?.first_name} {userDetail?.last_name}</p>
                              </Col>
                            </Row>
                            {/* console.log({assetUrl}/{userDetail?.photo}) */}

                            <Row>
                              <Col md={6}>
                                <label>Email</label>
                              </Col>
                              <Col md={6}>
                                <p>{userDetail?.email_address}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <label>Phone</label>
                              </Col>
                              <Col md={6}>
                                <p>{userDetail?.mobile_number}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <label>User Type</label>
                              </Col>
                              <Col md={6}>
                                <p>
                                  {userDetail?.userType.name.replace("_", " ")}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Profile;
