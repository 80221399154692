import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Button, Modal, Form, Alert } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Pencil, Eye } from "lucide-react";
import moment from "moment";
import { PlusCircle } from "lucide-react";
import Avatar from "./Avatar";

const PropertyNotes = () => {
  const { userDetails, token } = useSelector((state) => state.auth.user);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userType = userDetails?.userType;
  const userId = userDetails?.id;
  const { propertyId } = useParams();
  const [selectedNote, setSelectedNote] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [totalnotes, setTotalNotes] = useState();
  const [newNote, setNewNote] = useState("");
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  
  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    const payload = {
      note: newNote,
      property_id: propertyId,
    };

    try {
      const response = await axios.post(
        // "https://services.allhomedesire.in/property/notes/add",
        `${baseUrl}/property/notes/add`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessMessage(true);
        setShowAddNoteModal(false);
        setNewNote("");
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
        fetchNotes();
      }
    } catch (error) {
      setShowFailureMessage(true);
      setTimeout(() => {
        setShowFailureMessage(false);
      }, 3000);
    }
  };
  
  const fetchNotes = async () => {
    try {
      const payload = {
        post_id: 0,
        property_id: propertyId,
        page_number: 1,
        page_size: 20,
        order_by: "ASC",
      };
  
      const response = await axios.post(
        `${baseUrl}/property/partner-notes`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        setNotes(response.data.partnerNotesList);
        setTotalNotes(response.data.totalRecord);
        console.log(response.data.partnerNotesList);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };
  
  useEffect(() => {
    fetchNotes();
  }, [propertyId, token]);


  const handleEdit = (note) => {
    setSelectedNote(note);
    setEditedContent(note.note);
    setShowEditModal(true);
  };

  const handleSaveEdit = async () => {
    try {
      const payload = {
        note: editedContent,
        id: selectedNote.id,
      };

      const response = await axios.put(
        "https://services.allhomedesire.in/property/notes/edit",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        setNotes(notes.map((note) => (note.id === selectedNote.id ? { ...note, note: editedContent } : note)));
        setShowEditModal(false);
      }
    } catch (error) {
      console.error("Error editing note:", error);
    }
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + " ..." : text;
  };
  return (
    <div className="container py-4">
      {/* add note */}
      <div className="container text-center my-4">
        <Button variant="primary" onClick={() => setShowAddNoteModal(true)}>
          <PlusCircle size={18} className="me-2" /> Add Note
        </Button>

        {showSuccessMessage && (
          <Alert variant="success" className="mt-3">
            Note added successfully!
          </Alert>
        )}

        {showFailureMessage && (
          <Alert variant="danger" className="mt-3">
            Failed to add note. Please try again!
          </Alert>
        )}

        {/* Modal for Adding Note */}
        <Modal show={showAddNoteModal} onHide={() => setShowAddNoteModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add a New Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Note Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                  placeholder="Enter your note here..."
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddNoteModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleAddNote}>
              Save Note
            </Button>
          </Modal.Footer>
        </Modal>
        
      </div>

      <h2 className="text-center mb-4 text">Property Notes (total notes: {totalnotes})</h2>
      <div className="row g-4 justify-content-center">
        {notes && notes.length > 0 ? (
          notes.map((note) => (
            <div key={note.id} className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center">
              <Card 
                className="shadow-lg border-0 p-2 bg-white rounded-3 w-100"
                style={{ maxWidth: "400px"}}
              >
                <Card.Body >
                  <div className="d-flex gap-2 ">
                    {/* Partner Image and name*/}
                    {/* {note.partner?.photo && (
                      <span className="d-flex">
                        <img
                          src={note.partner.photo}
                          alt={`${note.partner.first_name}'s profile`}
                          className="rounded-circle shadow-sm "
                          style={{ width: "40px", height: "40px", objectFit: "cover", border: "1px solid #777" }}
                        />
                      </span>
                    )} */}

                  <Avatar icon={note.partner.first_name} src={note.partner.image} size={30}/>

                    {note.partner && (
                      <h5 className="fw-bold text-dark align-self-center m-0">{note.partner.first_name}</h5>
                    )}
                  </div>
                  <h6 className=" text-secondary pt-4 ">{truncateText(note.note, 15)}</h6>

                  <p className="text-muted fw-normal small">
                  Last Updated: {moment(note.created_on, "DD-MM-YYYY HH:mm:ss").fromNow()}
                </p>


                 {/* Action Buttons */}
                  <div className="d-flex justify-content-center gap-3 mt-3">
                    <Button 
                      variant="outline-primary"
                      size="sm" 
                      className="px-3 d-flex align-items-center" 
                      onClick={() => { setSelectedNote(note); 
                      setShowModal(true); }}
                    >
                      <Eye size={18} className="me-1" /> View
                    </Button>
                    <Button 
                      variant="outline-secondary" 
                      size="sm" 
                      className="px-3 d-flex align-items-center" 
                      onClick={() => handleEdit(note)}
                    >
                      <Pencil size={18} className="me-1" /> Edit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))
        ) : (
          <div className="text-center text-muted mt-4">
            <p className="fs-5">No notes available.</p>
          </div>
        )}
      </div>



      {/* Modal for Viewing Note Details */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedNote?.note}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Editing Note */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Control as="textarea" rows={3} value={editedContent} onChange={(e) => setEditedContent(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveEdit}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PropertyNotes;