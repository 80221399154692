import React, { useState } from "react";
import { Col, Container, Row, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../features/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../../vendor/common/Header";
import {
  FaUser,
  FaUserFriends,
  FaLayerGroup,
  FaAddressBook,
  FaBuromobelexperte,
  FaGlobe,
  FaUsers,
  FaChartArea,
  FaCity,
  FaCheck,
  FaSearchengin,
  FaSignal,
  FaStar,
  FaBars,
} from "react-icons/fa";

import "../Style.css";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#ffff",
      icon: "#000",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#000",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#0b2948",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {};

export const AdminHeader = () => {
  const loggedInUser = useSelector((state) => state.auth);
  const userRoles = loggedInUser?.user?.userDetails?.roles;
  const userType = loggedInUser?.user?.userDetails?.userType;

  const [expandedSubMenu, setExpandedSubMenu] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const navLinks = [
    {
      heading: "User Management",
      links: [
        {
          to: "/user",
          label: "Customer",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaUser />,
        },
        {
          to: "/partners",
          label: "Partner",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaUser />,
        },
        {
          to: "/allhomedesire",
          label: "Employee",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaUser />,
        },
        {
          to: "/user-type",
          label: "User Type",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaUsers />,
        },
      ],
    },
    {
      heading: "Core Data Management",
      links: [
        {
          to: "/service",
          label: "Services",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaCheck />,
        },
        {
          to: "/property-purpose",
          label: "Property Purpose",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaSearchengin />,
        },
        {
          to: "/property-type",
          label: "Property Type",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaStar />,
        },
        {
          to: "/property-subtype",
          label: "Property Sub Type",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaStar />,
        },
        {
          to: "/property-sub-sub-type",
          label: "Property Sub Sub Type",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaStar />,
        },
        {
          to: "/property-status",
          label: "Property Status",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaSignal />,
        },
        {
          to: "/land-types",
          label: "LandType",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaSignal />,
        },
      ],
    },
    {
      heading: "Location Management",
      links: [
        {
          to: "/country",
          label: "Country",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaGlobe />,
        },
        {
          to: "/state",
          label: "State",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaBuromobelexperte />,
        },
        {
          to: "/city",
          label: "City",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaCity />,
        },
        {
          to: "/area",
          label: "Area",
          allowed: ["SUPER_ADMIN", "ALL_HOME_DESIRE"],
          icon: <FaChartArea />,
        },
      ],
    },
  ];

  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = useState("dark");

  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  const menuItemStyles = {
    root: {
      fontSize: "15px",
      fontWeight: 400,
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    icon: {
      color: "#000",
      [`&.${menuClasses.disabled}`]: {
        color: "#000",
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
        width: "300px", // Increase the width to 300px
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div>
      <Row>{/* Sidebar */}</Row>
      {userType === "PARTNER" && (
        <UserMenu userType={userType} userRoles={userRoles} />
      )}

      {userType === "ALL_HOME_DESIRE" ||
        (userType === "SUPER_ADMIN" && (
          <div>
            <Sidebar
              collapsed={collapsed}
              toggled={toggled}
              onBackdropClick={() => setToggled(false)}
              onBreakPoint={setBroken}
              breakPoint="md"
            >
              <div>
                <div style={{ marginBottom: "32px" }}>
                  <div></div>
                  <Menu menuItemStyles={menuItemStyles}>
                    {navLinks.map((group, index) => (
                      <SubMenu
                        key={index}
                        label={group.heading}
                        open={expandedSubMenu === index} // Control open state
                        onOpenChange={(open) => {
                          if (open) {
                            setExpandedSubMenu(index);
                          } else {
                            setExpandedSubMenu(null);
                          }
                        }}
                        style={{
                          width: "245px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {group.links.map((link, linkIndex) =>
                          (link.allowed && link.allowed.includes(userType)) ||
                          !link.allowed ? (
                            <MenuItem
                              key={linkIndex}
                              icon={link.icon}
                              style={{ width: "100%" }}
                            >
                              <Link to={link.to}>{link.label}</Link>
                            </MenuItem>
                          ) : null
                        )}
                      </SubMenu>
                    ))}
                  </Menu>
                  <div
                    style={{
                      padding: "0 24px",
                      marginBottom: "8px",
                      marginTop: "32px",
                    }}
                  > </div>
                </div>
              </div>
            </Sidebar>
            <main>
              <div>
                <div className="text-end">
                  {broken && (
                    <button
                      className="sb-button btn btn-primary"
                      onClick={() => setToggled(!toggled)}
                    >
                      <FaBars />
                    </button>
                  )}
                </div>
                {/* <div style={{ padding: "0 8px" }}>
              <div style={{ marginBottom: 16 }}></div>
              <div style={{ marginBottom: 16 }}></div>
              <div style={{ marginBottom: 16 }}>
                <label>
                  Dark theme
                  <input
                    type="checkbox"
                    checked={theme === "dark"}
                    onChange={handleThemeChange}
                  />
                </label>
              </div>
              <div style={{ marginBottom: 16 }}></div>
            </div> */}
              </div>
            </main>
          </div>
        ))}
    </div>
  );
};

export default AdminHeader;
