import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Alert } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import "../../Style.css";
import AdminHeader from "../../common/Header";
import List from "../../components/listing/List";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import Api from "../../components/api/Api";
import { useSelector } from "react-redux";
import axios from "axios";
import Notification, {
  showToast,
} from "../../../components/common/notification/Notification";
import AllMessage from "../../../components/common/allMessage/AllMessage";
import Tr from "../../components/tr/Tr";
import { apiCall } from "../../../components/common/ApiCall";

const Partner = () => {
  const propertyApi = Api({
    url: "admin/users",
  });
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const userType = loggedInUser?.userDetails?.userType;
  const token = loggedInUser?.token;
  const handleDeleteAlertClose = () => setShowDeleteAlert(false);
  const handleDeleteAlertShow = () => setShowDeleteAlert(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/admin/deleteUser/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.data.statusType === "SUCCESS") {
        setData(data.filter((item) => item.id !== itemId));
        showToast("User deleted successfully", {
          type: "success",
        });
      } else {
        console.error(
          "Error 'deleting' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error 'deleting' item:", error);
      showToast("Error Deleting Item", {
        type: "error",
      });
    }
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columns = [
    { displayName: "S.No.", render: (item, index) => index + 1 },
    { displayName: "First Name", key: "first_name" },
    // { displayName: "Last Name", key: "last_name" },
    // { displayName: "Mobile Number", key: "mobile_number" },
    { displayName: "Email Address", key: "email_address" },
    { displayName: "Status", key: "status" },
  ];
  const fetchPropertyData = async (pageNumber = 1) => {
    try {
      // const response = await propertyApi.fetchData(pageNumber);
      const postData = {
        page_number: pageNumber,
        page_size: 10,
        order_by: "DSC",
        user_type: "PARTNER",
      };
      const response = await axios.post(`${baseUrl}/admin/users`, postData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(response.data.users);
      setTotalPages(response.data.totalPage);
      console.log("your response data is", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPropertyData(currentPage);
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const initialValues = {};
  const breadcrumbItems = [
    { label: "", link: "/admin", icon: "fa-solid fa-house" },
    { label: "Master", link: "/user" },

    // userType === "PARTNER"
    //   ? { label: "Team Management", link: "/user", active: true }
    //   : { label: "User Management", link: "/user", active: true },

    { label: "Team Management", link: "/user", active: true },
  ];
  const editDetailsApi = Api({
    url: `admin/editUser`,
  });
  const handleToggleEnable = async (itemId, enabled) => {
    console.log("your enables is", enabled);
    const response = await apiCall(
      `${baseUrl}/admin/user/state`,
      "put",
      {
        id: itemId,
        active_status: enabled,
      },
      token
    );
    if (response) {
      console.log("your response is", response);
      if (response?.statusType === "SUCCESS") {
      //   showToast("User Disabled Successfully", {
      //     type: "success",
      //   });
      // }

           showToast(
              enabled ? "User Enabled Successfully" : "User Disabled Successfully",
              { type: "success" }
            );
          }

      fetchPropertyData();
      // fetchData();
    }
  };
  <Row>
  <Col lg={3} md={4} sm={12}>
        <AdminHeader />
      </Col>
      <Col lg={9} md={8} sm={12}>
      </Col>
  </Row>
  return (
    <>
      <Notification />
      <div className="content-body">
        <Container fluid style={{ padding: "0rem 2rem" }}>
          {/* Delete Alert */}
          <BreadCrumb items={breadcrumbItems} />
          <Alert
            show={showDeleteAlert}
            variant="danger"
            onClose={handleDeleteAlertClose}
            dismissible
          >
            <Alert.Heading>Confirm Delete</Alert.Heading>
            <p>Are you sure you want to delete this user?</p>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleDeleteAlertClose}
                variant="outline-danger"
                className="me-3"
              >
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="danger">
                Delete
              </Button>
            </div>
          </Alert>

          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12}>
              {data.length === 0 ? (
                <>
                  <Tr
                    columns={columns}
                    initialValues={initialValues}
                    title="No Partner Found"
                    btnLink="/user/add-user"
                    titleHeader={"Partners"}
                  />
                  {/* <Row>
                    <Col>
                      <AllMessage title="No User Found" />
                    </Col>
                  </Row>

                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Link to={"/user/add-user"} className="btn btn-primary">
                      Add User
                    </Link>
                  </Col> */}
                </>
              ) : (
                // title="User Management"
                <List
                  // title={
                  //   userType === "PARTNER"
                  //     ? "Team Management"
                  //     : "User Management"
                  // }
                  title="Partners"
                  data={data}
                  columns={columns}
                  button="Create"
                  route="partners"
                  to="/user/add-user"
                  user={true}
                  isPartner={true}
                  handleToggleEnable={handleToggleEnable}
                  handleDelete={handleDelete}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Partner;
