import React, { useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormData,
  nextStep,
  prevStep,
} from "../../../features/formData/formSlice";

const AddPropertyDetails = () => {
  const formData = useSelector((state) => state.form.formData);

  // Define initial values for the form fields
  const initialValues = {
    bedrooms: formData?.bedrooms || "",
    bathrooms: formData?.bathrooms || "",
    rooms: formData?.rooms || "",
    tags: formData?.tags || "",
    pin_code: formData?.pin_code || "",
    address: formData?.address || "",
    swimming_pool: formData?.swimming_pool || false,
    gas_connection: formData?.gas_connection || false,
    ro: formData?.ro || false,
    club_house: formData?.club_house || false,
    basketball_court: formData?.basketball_court || false,
    tennis_court: formData?.tennis_court || false,
    gym: formData?.gym || false,
    indoor_games: formData?.indoor_games || false,
    child_play_area: formData?.child_play_area || false,
    hospital_near_by: formData?.hospital_near_by || false,
    mall_near_by: formData?.mall_near_by || false,
    market_near_by: formData?.market_near_by || false,
    school_near_by: formData?.school_near_by || false,
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    bedrooms: Yup.number(),
    bathrooms: Yup.number(),
    rooms: Yup.number(),
  });

  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    const combinedFormData = {
      ...formData,
      feature: {
        id: formData?.feature_id,
        swimming_pool: values?.swimming_pool,
        gas_connection: values?.gas_connection,
        ro: values?.ro,
        club_house: values?.club_house,
        basketball_court: values?.basketball_court,
        tennis_court: values?.tennis_court,
        gym: values?.gym,
        indoor_games: values?.indoor_games,
        child_play_area: values?.child_play_area,
        hospital_near_by: values?.hospital_near_by,
        mall_near_by: values?.mall_near_by,
        market_near_by: values?.market_near_by,
        school_near_by: values?.school_near_by,
      },
      ...values,
    };

    // Dispatch combined form data to Redux store
    dispatch(setFormData(combinedFormData));
    // Navigate to the next step
    dispatch(nextStep());
    setSubmitting(false);
  };
  useEffect(() => {
    // Update the initial values when the form data in the Redux store changes
    // This ensures the form initializes correctly when navigating back to it
    initialValues.bedrooms = formData?.bedrooms || "";
    initialValues.bathrooms = formData?.bathrooms || "";
    initialValues.rooms = formData?.rooms || "";
    initialValues.tags = formData?.tags || "";
  }, [formData]);
  const handlePrevious = (values) => {
    // Combine previous form data with new values
    const combinedFormData = {
      ...formData, // Previous form data
      ...values, // New values
    };

    // Dispatch combined form data to Redux store
    dispatch(setFormData(combinedFormData));
    // Navigate to the previous step
    dispatch(prevStep());
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="p-4 bg-light rounded shadow-sm">
          <div className="mb-4 fs-3 text-uppercase fw-bold">Internal Structure</div>

          {/* <Row className="gy-3">
            <Col md={4}>
              <div className="">
                <label htmlFor="bedrooms" className="form-label">
                  Bedrooms
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="bedrooms"
                  placeholder="Enter Bedrooms"
                  name="bedrooms"
                  style={{
                    height: "2.3rem",
                    padding: ".375rem 2.25rem .375rem .75rem",
                    backgroundColor: "#fff",
                  }}
                />
                <ErrorMessage
                  name="bedrooms"
                  component="div"
                  className="text-danger"
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="">
                <label htmlFor="bathrooms" className="form-label">
                  Bathrooms
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="bathrooms"
                  placeholder="Enter Bathrooms"
                  name="bathrooms"
                  style={{
                    height: "2.3rem",
                    padding: ".375rem 2.25rem .375rem .75rem",
                    backgroundColor: "#fff",
                  }}
                />
                <ErrorMessage
                  name="bathrooms"
                  component="div"
                  className="text-danger"
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="">
                <label htmlFor="rooms" className="form-label">
                  Rooms
                </label>
                <Field
                  type="number"
                  className="form-control"
                  id="rooms"
                  placeholder="Enter Rooms"
                  name="rooms"
                  style={{
                    height: "2.3rem",
                    padding: ".375rem 2.25rem .375rem .75rem",
                    backgroundColor: "#fff",
                  }}
                />
                <ErrorMessage
                  name="rooms"
                  component="div"
                  className="text-danger"
                />
              </div>
            </Col>
            <Col md={4} className="mt-3">
              <label htmlFor="title" className="form-label">
                Tags (Each tag separated by comma ,)
              </label>
              <Field
                type="text"
                className="form-control"
                id="title"
                placeholder="Tags"
                name="tags"
                style={{
                  height: "2.3rem",
                  padding: ".375rem 2.25rem .375rem .75rem",
                  backgroundColor: "#fff",
                }}
              />
              <ErrorMessage
                name="tags"
                component="div"
                className="text-danger"
              />
            </Col>
            <Col md={4} className="mt-3">
              <label htmlFor="pin_code" className="form-label">
                Pin Code
              </label>
              <Field
                type="text"
                className="form-control"
                id="pin_code"
                placeholder="Enter pin code"
                name="pin_code"
                style={{
                  height: "2.3rem",
                  padding: ".375rem 2.25rem .375rem .75rem",
                  backgroundColor: "#fff",
                }}
              />
              <ErrorMessage
                name="pin_code"
                component="div"
                className="text-danger"
              />
            </Col>
            <Col md={4} className="mt-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <Field
                type="text"
                className="form-control"
                id="address"
                placeholder="Enter address"
                name="address"
                style={{
                  height: "2.3rem",
                  padding: ".375rem 2.25rem .375rem .75rem",
                  backgroundColor: "#fff",
                }}
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-danger"
              />
            </Col>
          </Row> */}
          <Row className="mb-4 row-gap-2 mt-4">
            {[
              { label: "Bedrooms", name: "bedrooms" },
              { label: "Bathrooms", name: "bathrooms" },
              { label: "Rooms", name: "rooms" },
              { label: "Tags", name: "tags", type: "text" },
              { label: "Pin Code", name: "pin_code", type: "text" },
              { label: "Address", name: "address", type: "text" },
            ].map(({ label, name, type = "number" }, index) => (
              <Col md={4} className="mb-3" key={index}>
                <h5 htmlFor={name} className="mb-2">{label}</h5>
                <Field
                  type={type}
                  className="form-control bg-white h-10"
                  id={name}
                  placeholder={`Enter ${label}`}
                  name={name}
                />
                <ErrorMessage name={name} component="div" className="text-danger small" />
              </Col>
            ))}
          </Row>
          {/* <Row className="mb-4">
            <h6>Amenities</h6>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="SwimmingPool"
                  name="swimming_pool"
                />
                <label className="form-check-label" htmlFor="SwimmingPool">
                  Swimming Pool
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="GasConnection"
                  name="gas_connection"
                />
                <label className="form-check-label" htmlFor="GasConnection">
                  Gas Connection
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="RO"
                  name="ro"
                />
                <label className="form-check-label" htmlFor="RO">
                  RO
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="ClubHouse"
                  name="club_house"
                />
                <label className="form-check-label" htmlFor="ClubHouse">
                  Club House
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="BasketballCourt"
                  name="basketball_court"
                />
                <label className="form-check-label" htmlFor="BasketballCourt">
                  Basketball Court
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="TennisCourt"
                  name="tennis_court"
                />
                <label className="form-check-label" htmlFor="TennisCourt">
                  Tennis Court
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="Gym"
                  name="gym"
                />
                <label className="form-check-label" htmlFor="Gym">
                  Gym
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="IndoorGames"
                  name="indoor_games"
                />
                <label className="form-check-label" htmlFor="IndoorGames">
                  Indoor Games
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="ChildPlayArea"
                  name="child_play_area"
                />
                <label className="form-check-label" htmlFor="ChildPlayArea">
                  Child Play Area
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="HospitalNearBy"
                  name="hospital_near_by"
                />
                <label className="form-check-label" htmlFor="HospitalNearBy">
                  Hospital Near By
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="MallNearBy"
                  name="mall_near_by"
                />
                <label className="form-check-label" htmlFor="MallNearBy">
                  Mall Near By
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="MarketNearBy"
                  name="market_near_by"
                />
                <label className="form-check-label" htmlFor="MarketNearBy">
                  Market Near By
                </label>
              </div>
            </Col>
            <Col md={2}>
              <div className="form-check mb-3">
                <Field
                  className="form-check-input"
                  type="checkbox"
                  id="SchoolNearBy"
                  name="school_near_by"
                />
                <label className="form-check-label" htmlFor="SchoolNearBy">
                  School Near By
                </label>
              </div>
            </Col>
          </Row> */}

          <div className="mb-4 fs-3 text-uppercase fw-bold">Amenities</div>
          <Row className="gy-2">
            {[
              "Swimming Pool", "Gas Connection", "RO", "Club House", "Basketball Court",
              "Tennis Court", "Gym", "Indoor Games", "Child Play Area", "Hospital Near By",
              "Mall Near By", "Market Near By", "School Near By",
            ].map((amenity, index) => {
              const id = amenity.replace(/\s+/g, "_").toLowerCase();
              return (
                <Col md={2} key={index} className="mb-2 d-flex flex-wrap g-2">
                  <div className="form-check d-flex">
                    <Field className="form-check-input mr-2" type="checkbox" id={id} name={id} />
                    <label className="form-check-label " htmlFor={id}>{amenity}</label>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="d-flex justify-content-between mt-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => handlePrevious(values)}
            >
              Previous
            </button>
            <div>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                style={{ marginRight: "1rem" }}
              >
                {isSubmitting ? "Submitting..." : "Skip"}
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Next"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPropertyDetails;
