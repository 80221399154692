// import React, { useState, useEffect } from "react";
// import { Row, Col, Spinner, Container } from "react-bootstrap";
// import Select from "react-select";
// import PropertySingleCard from "../../components/Property/PropertySingleCard";
// import PostSingleCard from "../../components/post/PostSingleCard";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import TabMenu from "./TabMenu";
// import Pagination from "../../components/pagination/Pagination";
// import PropertyLikePostCard from "../../components/post/PropertyLikePostCard";

// const Tab3 = () => {
//   const loggedInUser = useSelector((state) => state.auth.user);
//   const fetchOptions = useSelector((state) => state.mySpace.option);
//   const userId = loggedInUser?.userDetails?.id;
//   const token = loggedInUser?.token;
//   const userType = loggedInUser?.userDetails?.userType;
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   console.log();
//   const [data, setData] = useState([]);
//   const [selectedValue, setSelectedValue] = useState( "property");
//   const [assetUrl, setAssetUrl] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };

//   const fetchData = async (pageNumber, pageSize = 10) => {
//     setIsLoading(true);
//     const postData = {
//       search_type: capitalizeFirstLetter(selectedValue),
//       page_number: pageNumber,
//       page_size: pageSize,
//       order_by: "DSC",
//     };
//     try {
//       const response = await axios.post(
//         `${baseUrl}/property/list/my/space`,
//         postData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
  
//       if (selectedValue === "post" && response.data.leads) {
//         response.data.leads = response.data.leads.map((lead) => ({
//           is_published: lead?.published,
//           active: lead.active,
//           deleted: lead.deleted,
//           id: lead.id,
//           title: lead.title || null,
//           description: lead.description,
//           budget: lead.budget || null,
//           created_on: lead.created_on,
//           customer_id: lead.customer_id,
//           customer_name: lead.customer_name,
//           partner_id: lead.partner_id,
//           partner_name: lead.partner_name,
//           comment_count: lead.comment_count,
//           area_name: lead.area_name,
//           city_name: lead.city_name,
//           state_name: lead.state_name,
//           country_name: lead.country_name,
//           images: lead?.images || [],
//         }));
//       }
      
//       if (selectedValue === "post" && response.data.leads) {
//         setData(response.data.leads);
//         setAssetUrl(response.data.assetUrl);
//       } else if (selectedValue === "property" && response.data.properties) {
//         setData(response.data.properties);
//         setAssetUrl(response.data.assetUrl);
//       } else {
//         setData([]);
//       }
      
//       setAssetUrl(response.data.assetUrl);
//       setTotalPages(response.data.totalPage > 0 ? response.data.totalPage : 1);

//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData(currentPage);
//   }, [selectedValue, currentPage]);

//   const selectOptions = [
//     { label: "Shared", value: "Shared" },
//     { label: "Posts", value: "post" },
//     { label: "Properties", value: "property" },
//     { label: "Follow", value: "follow" },
//   ];

//   return (
//     <>
//       <Container>
//         <TabMenu />
//         <Col md="12" className="mt-2">
//           <div className="widget-boxed main-search-field">
//             <Row>
//              <Col md="12">
//                 <Select
//                   options={selectOptions}
//                   value={selectOptions.find(
//                     (option) => option.value === selectedValue
//                   )}
//                   placeholder="Select Type"
//                   onChange={(selectedOption) => {
//                     setSelectedValue(selectedOption.value);
//                     setCurrentPage(1); // Reset to page 1 when changing filter
//                   }}
                  
//                   styles={{
//                     menuPortal: (base) => ({ ...base, zIndex: 999999 }),
//                     valueContainer: (provided) => ({
//                       ...provided,
                 
//                     }),
//                   }}
//                 />
//               </Col>

//               {isLoading ? (
//                 <div className="d-flex align-items-center justify-content-center mt-5">
//                   <Spinner animation="border" />
//                 </div>
//               ) : (
//                 <>
//                   {data.length > 0 ? (
//                     selectedValue === "post" ? (
//                       <PropertyLikePostCard data={data} assetUrl={assetUrl} />
//                     ) : (
//                       <PropertySingleCard data={data} assetUrl={assetUrl} />
//                     )
//                   ) : (
//                     <p className="d-flex align-items-center justify-content-center mt-5">
//                       No Data To Show
//                     </p>
//                   )}
//                 </>
//               )}
//             </Row>
//           </div>
//         </Col>
//         <div className="mt-5">
//           <Pagination
//             currentPage={currentPage}
//             totalPages={totalPages}
//             onPageChange={handlePageChange}
//           />
//         </div>
//       </Container>
//     </>
//   );
// };

// export default Tab3;


import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Container } from "react-bootstrap";
import Select from "react-select";
import PropertySingleCard from "../../components/Property/PropertySingleCard";
import PropertyLikePostCard from "../../components/post/PropertyLikePostCard";
import { useSelector } from "react-redux";
import axios from "axios";
import TabMenu from "./TabMenu";
import Pagination from "../../components/pagination/Pagination";

const Tab3 = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  const userId = loggedInUser?.userDetails?.id;
  const token = loggedInUser?.token;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("property");
  const [assetUrl, setAssetUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const fetchData = async (pageNumber, pageSize = 10) => {
    setIsLoading(true);
    const postData = {
      search_type: capitalizeFirstLetter(selectedValue),
      page_number: pageNumber,
      page_size: pageSize,
      order_by: "DSC",
    };

    try {
      const response = await axios.post(
        `${baseUrl}/property/list/my/space`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data); // Debugging line

      if (selectedValue === "post" && response.data.leads) {
        response.data.leads = response.data.leads.map((lead) => ({
          is_published: lead?.published,
          active: lead.active,
          deleted: lead.deleted,
          id: lead.id,
          title: lead.title || null,
          description: lead.description,
          budget: lead.budget || null,
          created_on: lead.created_on,
          customer_id: lead.customer_id,
          customer_name: lead.customer_name,
          partner_id: lead.partner_id,
          partner_name: lead.partner_name,
          comment_count: lead.comment_count,
          area_name: lead.area_name,
          city_name: lead.city_name,
          state_name: lead.state_name,
          country_name: lead.country_name,
          images: lead?.images || [],
        }));
      }

      if (
        (selectedValue === "property" || selectedValue === "follow") &&
        response.data.properties
      ) {
        setData(response.data.properties);
        setAssetUrl(response.data.assetUrl);
      } else if (selectedValue === "post" && response.data.leads) {
        setData(response.data.leads);
        setAssetUrl(response.data.assetUrl);
      } else {
        setData([]);
      }

      setTotalPages(response.data.totalPage > 0 ? response.data.totalPage : 1);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Selected Value:", selectedValue); // Debugging line
    fetchData(currentPage);
  }, [selectedValue, currentPage]);

  const selectOptions = [
    { label: "Shared", value: "Shared" },
    { label: "Posts", value: "post" },
    { label: "Properties", value: "property" },
    { label: "Follow", value: "follow" },
  ];

  return (
    <>
      <Container>
        <TabMenu />
        <Col md="12" className="mt-2">
          <div className="widget-boxed main-search-field">
            <Row>
              <Col md="12">
                <Select
                  options={selectOptions}
                  value={selectOptions.find(
                    (option) => option.value === selectedValue
                  )}
                  placeholder="Select Type"
                  onChange={(selectedOption) => {
                    setSelectedValue(selectedOption.value);
                    setCurrentPage(1); // Reset to page 1 when changing filter
                  }}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                    valueContainer: (provided) => ({
                      ...provided,
                    }),
                  }}
                />
              </Col>

              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  {data.length > 0 ? (
                    selectedValue === "post" ? (
                      <PropertyLikePostCard data={data} assetUrl={assetUrl} />
                    ) : (
                      <PropertySingleCard data={data} assetUrl={assetUrl} />
                    )
                  ) : (
                    <p className="d-flex align-items-center justify-content-center mt-5">
                      No Data To Show
                    </p>
                  )}
                </>
              )}
            </Row>
          </div>
        </Col>
        <div className="mt-5">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>
    </>
  );
};

export default Tab3;
