  import React, { useState, useEffect } from 'react';
  import axios from 'axios';
  import { Link } from 'react-router-dom';
  import Notification, { showToast } from '../../../components/common/notification/Notification';
  import Spinner from '../../../components/common/spinner/Spinner';
  import { useSelector } from 'react-redux';
  import SingleDetailHeader from '../../components/singleDetail/SingleDetailHeader';
  import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
  import AdminHeader from '../../common/Header';
  import List from '../../components/listing/List';
  import { Container, Row, Col } from 'react-bootstrap';

  const LandType = () => {
    const loggedInUser  = useSelector((state) => state.auth.user);
    const userId = loggedInUser ?.userDetails?.id;
    const token = loggedInUser ?.token;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [landTypes, setLandTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleDelete = async (itemId) => {
      try {
        const response = await axios.delete(
          `${baseUrl}/property/deleteLandType/${itemId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        if (response.data.statusType === "SUCCESS") {
          setLandTypes(landTypes.filter((item) => item.id !== itemId));
          showToast("Land Type deleted successfully", {
            type: "success",
          });
        } else {
          console.error(
            "Error 'deleting' item. Server responded with status:",
            response.status
          );
        }
      } catch (error) {
        console.error("Error 'deleting' item:", error);
      }
    };

    

    const columns = [
      { displayName: 'S.No.', render: (item, index) => index + 1 },
      { displayName: 'Name', key: 'name' },
    ];

    const breadcrumbItems = [
      { label: '', link: '/admin', icon: 'fa-solid fa-house' },
      { label: 'Master', link: '/user' },
      { label: 'Land Types', link: '', active: true },
    ];

    const fetchLandTypes = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${baseUrl}/all/land/sub/types`);
        if (response.status === 200) {
          setLandTypes(response.data.landTypes);
        }
      } catch (error) {
        setIsError(true);
        showToast('Error Occurred', { type: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      fetchLandTypes();
    }, [currentPage]);

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

    return (
      <>
        <Notification />
        <Container>
          <BreadCrumb items={breadcrumbItems} />
          <Row>
            <Col lg={3} md={4} sm={12}>
              <AdminHeader />
            </Col>
            <Col lg={9} md={8} sm={12}>
              {isLoading ? (
                <Spinner loading={true} />
              ) : (
                <List
                  title="Land Types"
                  data={landTypes}
                  columns={columns}
                  button="create"
                  route="land-types"
                  to="add-land-type"
                  handleDelete={handleDelete}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  export default LandType;
