import React, { useState} from 'react';
import axios from 'axios';
import { Link,  useNavigate  } from 'react-router-dom';
import Notification, { showToast } from '../../../components/common/notification/Notification';
import Spinner from '../../../components/common/spinner/Spinner';
import { useSelector } from 'react-redux';
import SingleDetailHeader from '../../components/singleDetail/SingleDetailHeader';
import BreadCrumb from '../../components/breadcrumb/BreadCrumb';
import AdminHeader from '../../common/Header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';

const AddLandType = () => {
  const loggedInUser   = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = loggedInUser?.token;

  const initialValues = {
    name: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      setIsLoading(true);

      const data = {
        name: values.name,
      };

      const response = await axios.post(`${baseUrl}/property/addLandType`, data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.data.statusType === "SUCCESS") {
        showToast('Land Type added successfully', { type: 'success' });
        setTimeout(() => {
          navigate('/land-types?index');
        }, 3000);
      } else {
        console.error(
          "Error 'adding' item. Server responded with status:",
          response.status
        );
      }
    } catch (error) {
      setIsError(true);
      showToast('Error Occurred', { type: 'error' });
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { label: '', link: '/admin', icon: 'fa-solid fa-house' },
    { label: 'Master', link: '/user' },
    { label: 'Land Types', link: '/land-types' },
    { label: 'Add Land Type', link: '', active: true },
  ];

  return (
    <>
      <Notification />
      <Container>
        <BreadCrumb items={breadcrumbItems} />
        <Row>
          <Col lg={3} md={4} sm={12}>
            <AdminHeader />
          </Col>
          <Col lg={9} md={8} sm={12}>
            <SingleDetailHeader header="Add Land Type" description="Add the land type details section" />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter name" name="name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>

                  <button type="submit" className="btn btn-primary mt-5" style={{ marginBottom: '10rem' }} disabled={isSubmitting}>
                    {isSubmitting ? <Spinner loading={true} /> : 'Add Land Type'}
                  </button>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddLandType;