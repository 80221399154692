import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-WHPH1NSK3S", {
        page_path: location.pathname + location.search,
      });
      console.log(`Page view tracked: ${location.pathname + location.search}`);
    } else {
      console.warn("Google Analytics is not initialized.");
    }
  }, [location]);

  return null;
};

export default GoogleAnalytics;