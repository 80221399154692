import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_BASE_URL;
const API_URL = `${baseUrl}/all/property/list/all/purpose`;
const PROPERTY_TYPES = ["Villa", "Flat", "House"];

const fetchProperties = async (type) => {
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        page_number: 1,
        page_size: 1,
        order_by: "ASC",
        type: type,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    return null;
  }
};

const PropertiesPage = () => {
  const [properties, setProperties] = useState([]);
  const [assetUrl, setAssetUrl] = useState(''); // State to store fetched assetUrl
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return localStorage.getItem('authToken'); // Assuming authToken is stored in localStorage
  };

  useEffect(() => {
    const loadProperties = async () => {
      try {
        const fetchedPropertiesData = await Promise.all(
          PROPERTY_TYPES.map((type) => fetchProperties(type))
        );
        const properties = fetchedPropertiesData.filter((data) => data && data.statusType === 'SUCCESS');
        
        if (properties.length > 0) {
          setAssetUrl(properties[0].assetUrl); // Store the assetUrl from the first response
          setProperties(properties.flatMap((data) => data.properties)); // Flatten all properties into a single list
        }
      } catch (error) {
        console.error('Error loading properties:', error);
      }
    };
    loadProperties();
  }, []);

  // Handle View Details click
  const handleViewDetails = (propertyId) => {
    if (!isLoggedIn()) {
      navigate('/login');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <main className="flex-grow container px-4 py-5">
      <h2 className="text-center fw-bold mb-4">Featured Properties</h2>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
        {properties.map((property) => (
          // <div className="col" key={property.id}>
          //   <div className="card shadow-lg">
          //     <img
          //       src={`${assetUrl}${property.images[0]?.image_url}`} // Dynamically using fetched assetUrl
          //       className="card-img-top"
          //       style={{ height: "250px" }}
          //       alt={property.title}
          //     />
          //     <div className="card-body">
          //       <h3 className="card-title fw-bold">{property.title}</h3>
          //       <p className="text-muted">{property.address}, {property.city?.name}, {property.state?.name}</p>
          //       <div className="d-flex justify-content-between align-items-center">
          //         <span className="fs-4 fw-bold text-primary">
          //           {new Intl.NumberFormat("en-IN", {
          //             style: "currency",
          //             currency: "INR",
          //             maximumFractionDigits: 0,
          //           }).format(property.budget)}
          //         </span>
          //         <button className="btn btn-primary" onClick={() => handleViewDetails(property.id)}>View Details</button>
          //       </div>
          //     </div>
          //   </div>
          // </div>

          <div className="col" key={property.id}>
  <div className="card shadow-lg h-100" style={{ minHeight: "450px" }}> {/* Ensuring equal height */}
    <img
      src={`${assetUrl}${property.images[0]?.image_url}`}
      className="card-img-top"
      style={{ height: "250px", objectFit: "cover" }} // Fixed image height
      alt={property.title}
    />
    <div className="card-body d-flex flex-column justify-content-between">
      <h3 className="card-title fw-bold">{property.title}</h3>
      <p className="text-muted" >
        {property.address}, {property.city?.name}, {property.state?.name}
      </p>
      <div className="d-flex justify-content-between align-items-center mt-auto">
        <span className="fs-4 fw-bold text-primary">
          {new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            maximumFractionDigits: 0,
          }).format(property.budget)}
        </span>
        <button className="btn btn-primary" onClick={() => handleViewDetails(property.id)}>
          View Details
        </button>
      </div>
    </div>
  </div>
</div>

        ))}
      </div>

      {/* Our Services Section */}
      <h2 className="text-center fw-bold mb-4">Our Services</h2>
      <div className="row text-center mb-5">
        <div className="col-md-4">
          <div className="card shadow p-4">
            <i className="fas fa-home fs-1 text-primary mb-3"></i>
            <h3 className="fw-bold">Looking to Buy?</h3>
            <p className="text-muted">
              Find your dream property with our detailed listings and virtual tours.
            </p>
            <button className="btn btn-primary" onClick={() => handleViewDetails()}>Search Properties</button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow p-4">
            <i className="fas fa-dollar-sign fs-1 text-primary mb-3"></i>
            <h3 className="fw-bold">Want to Sell?</h3>
            <p className="text-muted">
              List your property and reach thousands of potential buyers.
            </p>
            <button className="btn btn-primary" onClick={() => {
              if (!isLoggedIn()) {
                navigate('/login');
              } else {
                navigate('/dashboard');
              }
            }}>List Your Property</button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="card shadow p-4">
            <i className="fas fa-bullhorn fs-1 text-primary mb-3"></i>
            <h3 className="fw-bold">Want to Promote?</h3>
            <p className="text-muted">
              Boost your property visibility with premium promotion services.
            </p>
            <button className="btn btn-primary" onClick={() => {
              if (!isLoggedIn()) {
                navigate('/login');
              } else {
                navigate('/dashboard');
              }
            }}>Promote Now</button>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <h2 className="text-center fw-bold mb-4">Why Choose Us</h2>
      <div className="row text-center">
        <div className="col-md-3">
          <div className="card shadow p-4">
            <i className="fas fa-award fs-2 text-primary mb-2"></i>
            <h4 className="fw-bold">Trusted Experience</h4>
            <p className="text-muted">Over 15 years of excellence in real estate services.</p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card shadow p-4">
            <i className="fas fa-hand-holding-usd fs-2 text-primary mb-2"></i>
            <h4 className="fw-bold">Best Deals</h4>
            <p className="text-muted">Competitive prices and exclusive property offers.</p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card shadow p-4">
            <i className="fas fa-user-tie fs-2 text-primary mb-2"></i>
            <h4 className="fw-bold">Expert Agents</h4>
            <p className="text-muted">Professional and dedicated real estate agents.</p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card shadow p-4">
            <i className="fas fa-clock fs-2 text-primary mb-2"></i>
            <h4 className="fw-bold">24/7 Support</h4>
            <p className="text-muted">Round-the-clock customer service and support.</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PropertiesPage;
