// // import { React, Link } from "react-router-dom";
// // import { Row } from "react-bootstrap";
// // import Col from "react-bootstrap/Col";
// // import PropertyImg5 from "../../assets/images/01.webp";
// // import Button from "../common/button/Button";
// // import AddedTodayCard from "../homepage/cards/addedToday/AddedTodayCard";

// // export const OfficeSpaces = () => {
// //   return (
// //     <>
// //       <Row className="g-4">
// //         <Col md={6}>
// //           <AddedTodayCard
// //             imgUrl={PropertyImg5}
// //             forWhat="For Rental"
// //             title="   Luxury Rental Villa"
// //             address="118-11 Sutphin Blvd Jamaica, NY 11434"
// //             price="From $3,850"
// //           />
// //         </Col>
// //         <Col md={6}>
// //           <AddedTodayCard
// //             imgUrl={PropertyImg5}
// //             forWhat="For Rental"
// //             title="   Luxury Rental Villa"
// //             address="118-11 Sutphin Blvd Jamaica, NY 11434"
// //             price="From $3,850"
// //           />
// //         </Col>
// //         <Col md={6}>
// //           <AddedTodayCard
// //             imgUrl={PropertyImg5}
// //             forWhat="For Rental"
// //             title="   Luxury Rental Villa"
// //             address="118-11 Sutphin Blvd Jamaica, NY 11434"
// //             price="From $3,850"
// //           />
// //         </Col>
// //         <Col md={6}>
// //           <AddedTodayCard
// //             imgUrl={PropertyImg5}
// //             forWhat="For Rental"
// //             title="   Luxury Rental Villa"
// //             address="118-11 Sutphin Blvd Jamaica, NY 11434"
// //             price="From $3,850"
// //           />
// //         </Col>
// //       </Row>
// //     </>
// //   );
// // };

// // export default OfficeSpaces;

// //Above code for static UI//
// //Below Code for Dynamic UI//

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PropertyCard = ({ property, assetUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageUrl = property.images.length > 0 
    ? `${assetUrl}${property.images[0].image_url}` 
    : "https://c4.wallpaperflare.com/wallpaper/575/667/599/trees-design-house-lawn-wallpaper-preview.jpg";

  return (
    <div
      className="col-lg-6 col-md-6 col-sm-12"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div >
        <div
          className="card shadow-sm border-0 rounded-4 position-relative"
          style={{ overflow: "hidden" }}
        >
          <img
            src={imageUrl}
            className="card-img"
            alt={property.title}
            style={{
              height: "320px",
              objectFit: "cover",
              transition: "transform 0.4s ease-in-out",
              transform: isHovered ? "scale(1.1)" : "scale(1)"
            }}
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{
              background: "linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7))",
              borderRadius: "10px",
            }}
          ></div>

          <div className="card-img-overlay d-flex flex-column justify-content-between text-white">
            <div className="d-flex">
              <span className="badge bg-success me-2" style={{ fontSize: "1.1rem" }}>Verified</span>
              <span className="badge bg-primary" style={{ fontSize: "1.1rem" }}>New</span>
            </div>

            <div>
              <p className="text-uppercase mb-1" style={{ fontSize: "0.7rem", fontWeight: "300" }}>
                For {property.purpose.name}
              </p>
              <h4 className="fw-bold text-white">{property.title}</h4>
              <div className="d-flex align-items-center justify-content-between">
                <p className="small mb-0 d-flex align-items-center" style={{ fontSize: "0.8rem", fontWeight: "200" }}>
                  <i className="bi bi-geo-alt-fill me-1" style={{ fontSize: "1rem" }}></i>
                  {property.area.name}, {property.city.name}, {property.state.name}
                </p>
                <div className="text-end">
                  <span className="btn-default btn-de-2 btn-group ms-n2 ms-sm-0 mt-0" style={{ backgroundColor: "#ef7f32", fontSize: "1rem", fontWeight: "bold" }}>From ₹ {property.budget.toLocaleString()} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OfficeSpaces = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assetUrl, setAssetUrl] = useState("");  // State to hold the asset URL

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/all/property/list/all/purpose`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              page_number: 1,
              page_size: 4,
              order_by: "ASC",
              type: "Villa",
            }),
          }
        );

        const data = await response.json();
        if (data.statusType === "SUCCESS") {
          setProperties(data.properties);
          setAssetUrl(data.assetUrl);  // Set the asset URL from the API response
        } else {
          console.error("Error fetching properties");
        }
      } catch (error) {
        console.error("Error fetching properties", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <div className="container mt-4">
      <div className="row g-4">
        {properties.map((property) => (
          <PropertyCard key={property.id} property={property} assetUrl={assetUrl} />
        ))}
      </div>
    </div>
  );
};

export default OfficeSpaces;
